import { useSearchParams } from 'react-router-dom';

export type SearchParam<T> = readonly [searchParamValue: T | undefined, setSearchParamValue: (newState?: T) => void];

type UseSearchParamValue = <T>(searchParamKey: string, defaultValue?: T | undefined) => SearchParam<T>;

// useSearchParams wrapper that provides a useState-like interface
// T is encoded using JSON.stringify & decoded using JSON.parse
export const useSearchParamValue: UseSearchParamValue = <T>(
  searchParamKey: string,
  defaultValue: T | undefined = undefined,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParamValue = (value?: T) => {
    const next = Object.fromEntries(searchParams);

    if (value) {
      // upsert into existing state (URL encoding is done by useSearchParams)
      next[searchParamKey] = JSON.stringify(value);
    }

    // unset undefined search params
    const valueIsEmptyArray = value && Array.isArray(value) && value.length === 0;
    const valueIsEmpty = !value;
    if (!next[searchParamKey] || valueIsEmpty || valueIsEmptyArray) {
      delete next[searchParamKey];
    }

    // update search params
    setSearchParams(next, {
      // use replace to make changes in search params not influence the browser history
      replace: true,
    });
  };

  const searchParamJsonValue = searchParams.get(searchParamKey);

  let searchParamValue: T | undefined;
  if (searchParamJsonValue) {
    try {
      searchParamValue = JSON.parse(searchParamJsonValue) as T;
    } catch (error) {
      console.error('Failed to parse search param value as JSON', { searchParamKey, searchParamJsonValue });
      searchParamValue = defaultValue;
      setSearchParamValue();
    }
  } else {
    searchParamValue = defaultValue;
  }

  return [searchParamValue, setSearchParamValue];
};
