/* eslint-disable prefer-rest-params */
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';
import { SCHEMA_VERSION } from './api/queries/schema-version.tsx';
import './globals.scss';
import './main.scss';
import "@fontsource/material-icons";

// When Google Translate modifies the DOM, the React Virtual DOM doesn't notice the changes and
// throws errors that result in whitescreens. This is a workaround to catch and log those errors
// to console instead of whitescreening the user.
// See https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const originalRemoveChild = Node.prototype.removeChild;
  // @ts-expect-error changing default react behavior
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    // @ts-expect-error changing default react behavior
    return originalRemoveChild.apply(this, arguments);
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const originalInsertBefore = Node.prototype.insertBefore;
  // @ts-expect-error changing default react behavior
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    // @ts-expect-error changing default react behavior
    return originalInsertBefore.apply(this, arguments);
  };
}

const CACHE_MAX_AGE = 1000 * 60 * 60 * 24 * 365; // 1 year

// create a react-query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // allows to use fetch when offline
      networkMode: 'offlineFirst',
      // garbage collects data after a year
      gcTime: CACHE_MAX_AGE,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const persistOptions = {
  queryClient,
  persister: localStoragePersister,
  // clears cache when either the data schema changes or there's a new version/build of the app
  buster: SCHEMA_VERSION.toString(),
  // clears cache after a year
  maxAge: CACHE_MAX_AGE,
  key: 'SIKA_MILJ_APPEN_OFFLINE_CACHE',
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      <App />
    </PersistQueryClientProvider>
  </React.StrictMode>,
);
