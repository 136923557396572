import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from './PATHS';
import './mobile-navigation.scss';
import './navigation.scss';

interface MobileNavigationProps {
  className?: string;
}

export const MobileNavigation = ({ className }: MobileNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = PATHS.find(({ to }) => location.pathname.split('/')[1] == to.split('/')[1]);

  return (
    <nav className={`mobile navigation ${className}`}>
      <ul className="pl-0 m-0 grid grid-flow-col">
        {PATHS.map((path) => {
          const { to, text, icon: IconElement } = path;

          const active = path === activePath;

          const onClick = () => navigate(to);

          return (
            <li
              key={to}
              onClick={onClick}
              className="cursor-pointer flex flex-col justify-center items-center gap-y-1.5"
            >
              <IconElement className={active ? 'active' : ''} />
              {active && <label className="text-medium tracking-wide">{text}</label>}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
