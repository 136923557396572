export enum HealthyHousesRating {
  A = 'a',
  B = 'b',
  C_PLUS = 'c_plus',
  C_MINUS = 'c_minus',
  D = 'd',
}

export const HEALTHY_HOUSES_RATING_STATUS = [
  {
    key: HealthyHousesRating.A,
    label: 'A',
  },
  {
    key: HealthyHousesRating.B,
    label: 'B',
  },
  {
    key: HealthyHousesRating.C_PLUS,
    label: 'C+',
  },
  {
    key: HealthyHousesRating.C_MINUS,
    label: 'C-',
  },
  {
    key: HealthyHousesRating.D,
    label: 'D',
  },
];

const RANKED_HEALTHY_HOUSES_RATING = [
  HealthyHousesRating.A,
  HealthyHousesRating.B,
  HealthyHousesRating.C_PLUS,
  HealthyHousesRating.C_MINUS,
  HealthyHousesRating.D,
];

// When filtering we want to include all statuses up to and including the selected status
export const getFilterOfHealthyHousesRating = (healthyHousesRating: HealthyHousesRating): HealthyHousesRating[] => {
  return RANKED_HEALTHY_HOUSES_RATING.slice(0, RANKED_HEALTHY_HOUSES_RATING.indexOf(healthyHousesRating) + 1);
};

export const toLabeledHealthyHousesRating = (healthyHousesRating?: HealthyHousesRating): string | undefined => {
  return HEALTHY_HOUSES_RATING_STATUS.find(({ key }) => key === healthyHousesRating)?.label;
};
