import { Item } from '../components/ui/list/list-item';

export const LABELS: Item[] = [
  {
    key: 'basta',
    link: 'basta',
    title: 'Basta',
    description: `BASTA-systemet vänder sig till alla som vill göra medvetna produktval i syftet att fasa ut ämnen med farliga egenskaper. Detta kan vara fastighetsägare, entreprenörer, arkitekter, byggkonstruktörer, privatpersoner med mera.
\nBASTA-systemet bidrar, genom att fasa ut ämnen med farliga egenskaper från bygg- och anläggningsprodukter, till att uppnå:
   •  Sveriges nationella miljömål ”Giftfri miljö”
   •  FNs globala mål ”Hållbar konsumtion och produktion”
   •  Kemikalieinspektionens arbete med "PRIO-verktyget"
\nBASTA stöds av samhällsbyggnadssektorns stora aktörer och av myndigheter så som Kemikalieinspektionen och Trafikverket.
\nBASTA är ett icke-vinstdrivande bolag och ägs av IVL Svenska Miljöinstitutet & Byggföretagen.
\nBASTA-nivån är den högsta nivån i systemet. Produkter som uppfyller denna nivå uppfyller kriterier som begränsar utfasningsämnen och riskminskningsämnen enligt Kemikalieinspektionens ”PRIO-verktyg”.
\nBETA-nivån är den näst högsta nivån i systemet. Produkter som uppfyller denna nivå uppfyller kriterier som begränsar utfasningsämnen enligt Kemikalieinspektionens ”PRIO-verktyg”.
\nDEKLARERAD Produkter som registreras med denna betygsnivå klarar inte samtliga kriterier för att nå BASTA- eller BETA-nivån.
\nBETA till BASTA gäller endast för kemiska produkter som förändras kemiskt när de använts, till exempel via härdning eller torkning. Betygsnivån innebär att produkten vid leverans uppfyller betygsnivå BETA men att den i sitt inbyggda skede klarar nivå BASTA.
\nDEKLARERAD till BASTA endast för kemiska produkter som förändras kemiskt när de använts, till exempel via härdning eller torkning. Betygsnivån innebär att produkten vid leverans uppfyller betygsnivå DEKLARERAD men att den i sitt inbyggda skede klarar nivå BASTA.`,
    imageSrc: '/labels/basta.png',
  },
  {
    key: 'sunda-hus',
    link: 'sunda-hus',
    title: 'Sunda Hus',
    description: `SundaHus i Linköping AB (publ) grundades 1990 som ett konsultbolag för en bättre inomhusmiljö.
\nSundaHus erbjuder fastighetsägare ett brett utbud av tjänster för medvetna materialval. Med ett webbaserat system och kvalificerad rådgivning är SundaHus en helhetslösning för att systematisera arbetet med att fasa ut farliga ämnen i en byggnads hela livscykel. 
\nSunda Hus bedömer produkter från D till A, där A är bäst.`,
    imageSrc: '/labels/sundahus.png',
  },
  {
    key: 'byggvarubedömningen-bvb',
    link: 'byggvarubedömningen-bvb',
    title: 'Byggvarubedömningen BVB',
    description: `BYGGVARUBEDÖMNINGEN BVB bedömer och tillhandahåller information om miljöbedömda varor och påverkar produktutvecklingen mot en giftfri och god bebyggd miljö. Många av Sveriges stora fastighetsägare och byggherrar har gått samman för att ta fram en standard för miljöbedömning av varor. BVB drivs som en ekonomisk förening.
\nProdukternas Totala bedömningen grundas på bedömning av Innehåll och Livscykeln. Produkterna bedöms enligt Rekommenderas, Accepteras och Undviks.`,
    imageSrc: '/labels/byggvaru-bedoemningen.png',
    imageUseFullWidth: true,
  },
  {
    key: 'svanens-husproduktportal',
    link: 'svanens-husproduktportal',
    title: 'Svanens husproduktportal',
    description: `För att kunna ansöka om en Svanenmärkning behöver de ansökande byggföretagen redovisa de byggmaterial, byggvaror och kemiska byggprodukter som de ska använda i byggprojektet. I husproduktportalen listas alla valbara produkter för licensinnehavare och företag som ansöker om en Svanenlicens.`,
  },
  {
    key: 'miljöbyggnad-3.0',
    link: 'miljöbyggnad-3.0',
    title: 'Miljöbyggnad 3.0',
    description: `MILJÖBYGGNAD 3.0 är utvecklat för svenska förhållanden och är det mest använda certifieringssystemet för byggnader i Sverige. Miljöbyggnad kan användas för både nya och befintliga byggnader, oavsett storlek. Miljöbyggnad ger ett kvitto på viktiga kvaliteter vad gäller energi, inomhusmiljö och material.
\nIndikator 13 visar vilken dokumentation som finns att tillgå och vilken nivå produkten kan uppnå med hjälp detta underlag.
\nIndikator 14 visar vilken nivå produktens innehåll klarar (utfasning av farliga ämnen).
      `,
    imageSrc: '/labels/miljo-byggnad.jpg',
  },
  {
    key: 'emissionsmätning',
    link: 'emissionsmätning',
    title: 'Emissionsmätning',
    description: `Emissionsmätningar visar enligt vilket system produktens emissionsmätningar är gjorda. Användbart för de som bygger enligt Miljöbyggnad och LEED.`,
    imageSrc: '/labels/miljöbyggnad-3.0.png',
  },
  {
    key: 'disclaimer',
    link: 'disclaimer',
    title: 'Disclaimer',
    description: `Informationen om aktuell bedömning av Sika Sveriges produkter lämnas i god tro baserat på vår nuvarande kunskap av produkternas bedömningar utifrån varje bedömningssystem. Med hänsyn härtill kan något rättsligt ansvar av vad slag det må vara varken härledas från denna information eller från någon skriftlig rekommendation eller i övrigt beträffande produkten lämnade råd. Användaren skall alltid använda sig av den senaste utgåvan av den aktuella produktens tekniska datablad och säkerställa bedömningen i respektive presenterat system.`,
    fullWidth: true,
  },
];
