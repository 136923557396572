import { useLocation, useNavigate } from 'react-router-dom';
import {
  BastaBetaStatus,
  BvbStatus,
  GreenBuildingRating,
  HEALTHY_HOUSES_RATING_STATUS,
  HealthyHousesRating,
  LABELED_BASTA_STATUS,
  LABELED_BVB_STATUS,
  LABELED_GREEN_BUILDING_RATING,
} from '../../api/enums';
import { BackButton } from '../../components/back-button';
import { PathBreadcrumbs } from '../../components/hierarchy-breadcrumbs/path-breadcrumbs';
import { Button } from '../../components/ui/button/button';
import { CheckboxInput } from '../../components/ui/checkbox-input/checkbox-input';
import { Dropdown } from '../../components/ui/dropdown/dropdown';
import { Label } from '../../utils/use-filtered-products';
import { useAppContainer } from '../components/use-app-container';
import './filter-search-page.scss';
import { useFilterSearchParams } from './use-filter-search-params';

export const FilterSearchPage = () => {
  useAppContainer({ scrollMainToTopOnDeps: [] });

  const {
    activeLabelsParam: [activeLabels, setActiveLabels],
    indicator13RatingParam: [indicator13Rating, setIndicator13Rating],
    indicator14RatingParam: [indicator14Rating, setIndicator14Rating],
    bvbContentStatusParam: [bvbContentStatus, setbvbContentStatus],
    bvbLifecycleStatusParam: [bvbLifecycleStatus, setbvbLifecycleStatus],
    bvbTotalStatusParam: [bvbTotalStatus, setBvbTotalStatus],
    healthyHousesRatingParam: [healthyHousesRating, setHealthyHousesRating],
    bastaBetaStatusParam: [bastaBetaStatus, setBastaBetaStatus],
  } = useFilterSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const onToggleLabel = (key: Label) => {
    const safeActiveLabels = activeLabels ?? [];
    setActiveLabels(
      safeActiveLabels.includes(key) ? safeActiveLabels.filter((label) => label !== key) : [...safeActiveLabels, key],
    );
  };

  const onSearchClick = () => {
    navigate('category' + location.search);
  };

  return (
    <div className="name-search-page">
      <div className="content mx-auto mt-4 mb-5 max-lg:px-5">
        <PathBreadcrumbs className="mt-4 mb-7" />
        <BackButton />
        <h1 className="text-center pt-5 lg:hidden">Miljöbedömning</h1>
        <div className="text-center pt-3 lg:hidden">Ange ditt namn eller artikelnummer här</div>
        <CheckboxInput<Label>
          options={[{ key: 'svanens-husportal', label: 'Svanens husportal' }]}
          value={activeLabels ?? []}
          onToggle={onToggleLabel}
          className="pt-6 lg:pt-10"
        />
        <div className="mt-5 lg:mt-6 grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-y-6 items-end">
          <Dropdown<GreenBuildingRating>
            value={indicator13Rating}
            onToggle={setIndicator13Rating}
            options={LABELED_GREEN_BUILDING_RATING}
            placeholder="Miljöbyggnad 3.0 - Indikator 13"
          />
          <Dropdown<GreenBuildingRating>
            value={indicator14Rating}
            onToggle={setIndicator14Rating}
            options={LABELED_GREEN_BUILDING_RATING}
            placeholder="Miljöbyggnad 3.0 - Indikator 14"
          />
          <Dropdown<BvbStatus>
            value={bvbContentStatus}
            onToggle={setbvbContentStatus}
            options={LABELED_BVB_STATUS}
            placeholder="BVB Innehåll"
          />
          <Dropdown<BvbStatus>
            value={bvbLifecycleStatus}
            onToggle={setbvbLifecycleStatus}
            options={LABELED_BVB_STATUS}
            placeholder="BVB Livscykel"
          />
          <Dropdown<BvbStatus>
            value={bvbTotalStatus}
            onToggle={setBvbTotalStatus}
            options={LABELED_BVB_STATUS}
            placeholder="BVB Total"
          />
          <Dropdown<HealthyHousesRating>
            value={healthyHousesRating}
            onToggle={setHealthyHousesRating}
            options={HEALTHY_HOUSES_RATING_STATUS}
            placeholder="Sunda Hus"
          />
          <Dropdown<BastaBetaStatus>
            value={bastaBetaStatus}
            onToggle={setBastaBetaStatus}
            options={LABELED_BASTA_STATUS}
            placeholder="BASTA"
          />
          <div className="lg:flex lg:flex-col lg:items-end lg:justify-end">
            <Button label="Sök" className="max-lg:w-full lg:flex" onClick={onSearchClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
