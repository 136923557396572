import { BackButton } from '../../components/back-button';
import { PathBreadcrumbs } from '../../components/hierarchy-breadcrumbs/path-breadcrumbs';
import { Image } from '../../components/ui/image/image';
import { Item } from '../../components/ui/list/list-item';
import { useAppContainer } from '../components/use-app-container';
import './label-page.scss';

interface LabelPageProps {
  item: Item;
}

export const LabelPage = ({ item }: LabelPageProps) => {
  useAppContainer({ scrollMainToTopOnDeps: [item] });

  const { title, description, imageSrc, imageAlt } = item;

  return (
    <div className="label-page mx-5 mt-4 mb-5">
      <div className="content mx-auto">
        <PathBreadcrumbs className="mt-4 mb-7" />
        <BackButton />
        {imageSrc && (
          <div className="flex justify-center">
            <Image imageProps={{ src: imageSrc, className: 'my-3', alt: imageAlt }} hideOnError={true} />
          </div>
        )}
        <h1 className="mb-5 pt-4">{title}</h1>
        <p className="whitespace-pre-wrap">{description}</p>
      </div>
    </div>
  );
};
