import { Fragment } from 'react';
import { StripedRows } from '../../../components/ui/striped-rows/striped-rows';
import { DATA_UNAVAILABLE_TEXT, TabContentProps } from './tab';

export const ProductInformationTabContent = ({ product }: TabContentProps) => {
  const { articles = [] } = product ?? {};

  return (
    <>
      {articles.length > 0
        ? articles?.map(
            ({
              articleNumber, // Artikelnr.
              europeanArticleNumber, // EAN
              color, // Färg
              packaging, // Förpackning
            }) => (
              <Fragment key={`${articleNumber}-${europeanArticleNumber}-${color}-${packaging}`}>
                <h6 className="px-5 pb-3 text-lg lg:text-xl uppercase">{`${packaging ?? 'Packaging unknown'} ${color ? `(${color})` : ''}`}</h6>
                <StripedRows
                  className="pb-6"
                  rows={[
                    {
                      key: 'article-number',
                      title: 'Artikelnr.',
                      text: articleNumber ?? DATA_UNAVAILABLE_TEXT,
                    },
                    {
                      key: 'färg',
                      title: 'Färg.',
                      text: color ?? DATA_UNAVAILABLE_TEXT,
                      asHtml: true,
                    },
                    {
                      key: 'förpackning',
                      title: 'Förpackning',
                      text: packaging ?? DATA_UNAVAILABLE_TEXT,
                      asHtml: true,
                    },
                    {
                      key: 'europeanArticleNumber',
                      title: 'EAN',
                      text: europeanArticleNumber ?? DATA_UNAVAILABLE_TEXT,
                    },
                  ].sort(({ title: titleA }, { title: titleB }) => titleA.localeCompare(titleB))}
                />
              </Fragment>
            ),
          )
        : DATA_UNAVAILABLE_TEXT}
    </>
  );
};
