import { AnchorHTMLAttributes, DetailedHTMLProps, JSX } from 'react';
import './inline-link.scss';

type InlineLinkProps = {
  children: JSX.Element | string;
} & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const InlineLink = (props: InlineLinkProps) => {
  return (
    <a {...props} className={`inline-link px-0.5 ${props.className}`}>
      {props.children}
    </a>
  );
};
