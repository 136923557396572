import { List } from '../../components/ui/list/list';
import { useAppContainer } from '../components/use-app-container';
import './search-page.scss';

export const SearchPage = () => {
  useAppContainer({ scrollMainToTopOnDeps: [] });

  const listItems = [
    {
      key: 'name',
      title: 'Sök med namn / artikelnummer / EAN',
      link: 'name',
    },
    {
      key: 'barcode',
      title: 'Scanna streckkod',
      link: 'barcode',
      hideOnDesktop: true,
    },
    {
      key: 'filter',
      title: 'Sök enligt miljöbedömning',
      link: 'filter',
    },
    {
      key: 'category',
      title: 'Sök på produktkategori',
      link: 'category',
    },
  ];

  return (
    <div className="search-page">
      <h1 className="text-center pt-10 lg:hidden">Sök produkt</h1>
      <div className="content mx-auto mt-7 mb-5 max-lg:px-5">
        <List
          items={listItems}
          className="mt-5"
          lightHeader
          fillBgOnHover
          extraListItemPadding
          listClassName="!grid-cols-1 lg:!grid-cols-2"
        />
      </div>
    </div>
  );
};
