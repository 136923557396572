import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { DesktopFooter } from '../../components/desktop-footer/desktop-footer';
import { DesktopHeader } from '../../components/header/desktop-header';
import { MobileHeader } from '../../components/header/mobile-header';
import { DesktopNavigation } from '../../components/navigation/desktop-navigation';
import { MobileNavigation } from '../../components/navigation/mobile-navigation';
import { updateApp } from '../../update-app';
import { AppContainerOutletContext } from './use-app-container';

export const AppContainer = () => {
  const mainRef = useRef<HTMLElement>(null);

  const scrollMainToTop = () => {
    if (mainRef.current) {
      mainRef.current.scrollTo({ top: 0 });
    }
  };

  // check for app updates on every focus and on initial mount
  useEffect(() => {
    window.addEventListener('focus', () => {
      void updateApp();
    });
    void updateApp();
  }, []);

  return (
    <div className="h-full flex flex-col">
      <MobileHeader className="grow-0 lg:hidden" />
      <DesktopHeader className="grow-0 max-lg:hidden" />
      <DesktopNavigation className="grow-0 max-lg:hidden" />
      <main id="main" ref={mainRef} className="grow overflow-auto flex flex-col">
        <div className="grow">
          <Outlet context={{ scrollMainToTop } satisfies AppContainerOutletContext} />
        </div>
        <DesktopFooter className="max-lg:hidden" scrollMainToTop={scrollMainToTop} />
      </main>
      <MobileNavigation className="grow-0 lg:hidden" />
    </div>
  );
};
