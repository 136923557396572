import { Link } from 'react-router-dom';
import './desktop-header.scss';
import './header.scss';

interface DesktopHeaderProps {
  className?: string;
}

export const DesktopHeader = ({ className }: DesktopHeaderProps) => {
  return (
    <div className={`p-5 pb-9 desktop header ${className}`}>
      <div className="content mx-auto">
        <Link to="/" className="flex gap-6 items-center w-max text-inherit no-underline">
          <img src="/logo.svg" />
          <h4 className="text-light">Miljöappen</h4>
        </Link>
      </div>
    </div>
  );
};
