import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ImageType } from '../../api/enums/ImageType';
import { Category, useGetCategories } from '../../api/queries/use-get-categories';
import { BackButton } from '../../components/back-button';
import { PathBreadcrumbs } from '../../components/hierarchy-breadcrumbs/path-breadcrumbs';
import { List } from '../../components/ui/list/list';
import { Item } from '../../components/ui/list/list-item';
import { findCategoryById } from '../../utils/find-category-by-id';
import { getAllNestedProducts } from '../../utils/get-all-nested-products';
import { useFilteredProducts } from '../../utils/use-filtered-products';
import { useAppContainer } from '../components/use-app-container';
import { useFilterSearchParams } from '../search-page/use-filter-search-params';
import './category-page.scss';

export const CategoryPage = () => {
  const { data, isLoading, isError } = useGetCategories();
  const {
    activeLabelsParam: [activeLabels],
    indicator13RatingParam: [indicator13Rating],
    indicator14RatingParam: [indicator14Rating],
    bvbContentStatusParam: [bvbContentStatus],
    bvbLifecycleStatusParam: [bvbLifecycleStatus],
    bvbTotalStatusParam: [bvbTotalStatus],
    healthyHousesRatingParam: [healthyHousesRating],
    bastaBetaStatusParam: [bastaBetaStatus],
  } = useFilterSearchParams();
  // filter products with search params values
  const {
    filteredProducts,
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
  } = useFilteredProducts({
    filter: {
      indicator13Rating,
      indicator14Rating,
      bvbContentStatus,
      bvbLifecycleStatus,
      bvbTotalStatus,
      healthyHousesRating,
      activeLabels,
      bastaBetaStatus,
    },
  });

  const {
    categoryIds: categoryIdsParam, // contains hierarchy of selected categories (e.g. "12-23-17")
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // scroll to top when a new category loads
  useAppContainer({ scrollMainToTopOnDeps: [categoryIdsParam] });

  const categories = data?.['hydra:member'];

  const onCategoryClick = (item: Item) => {
    if (categoryIdsParam) {
      // navigate one category depper (e.g. from */category/12-23 to */category/12-23-17)
      navigate(`${location.pathname}-${item.key}${location.search}`);
    } else {
      // navigate to */category/<id>
      navigate(`${item.key}${location.search}`);
    }
  };

  const onProductClick = (item: Item) => {
    navigate(`product/${item.key}${location.search}`);
  };

  let category: Category | undefined;
  const categoryIds = categoryIdsParam?.split('-') ?? [];
  if (categoryIds?.length > 0) {
    // get last category
    const categoryId = parseInt(categoryIds[categoryIds?.length - 1]);
    category = findCategoryById(categories ?? [], categoryId);
  } else {
    // category hierarchy is empty, create root category with all top-level categories
    category = {
      id: -1, // root category has no id
      name: 'Kategori',
      children: categories ?? [],
    };
  }

  const categoryProducts = category
    ? filteredProducts?.filter(({ categories }) => category?.id && categories.includes(category.id))
    : [];

  const childCategories = (category?.children ?? [])
    // filter out child categories without products
    .filter((childCategory) => {
      const allNestedProducts = getAllNestedProducts(filteredProducts ?? [], childCategory);
      return allNestedProducts.length !== 0;
    });
  const categoryName = category?.name ?? 'Kategori';
  const productsResultTitle = `${categoryName} (${categoryProducts?.length ?? 0})`;
  const hasCategoryProducts = categoryProducts && categoryProducts?.length > 0;

  // map categories and items to items usable inside lists
  const categoryItems =
    childCategories.map(({ id, name }) => ({ key: id, title: name, onClick: onCategoryClick })) ?? [];
  const categoryProductItems =
    categoryProducts?.map(({ id, name, images }) => {
      const { altText, imageUrl } = images?.find(({ type }) => type === ImageType.PRODUCT_PICTURE) ?? {};

      return {
        key: id,
        title: name,
        onClick: onProductClick,
        imageSrc: imageUrl,
        imageAlt: altText,
      };
    }) ?? [];
  const hasCategories = categoryItems.length > 0;

  return (
    <div className="category-page">
      <div className="content mx-auto mt-4 mb-5 max-lg:px-5">
        <PathBreadcrumbs className="mt-4 mb-7" />
        <BackButton />
        <h1 className="text-center pt-5 lg:hidden">{categoryName}</h1>
        <div className="text-center pt-3 lg:hidden pb-6">Ange ditt namn eller artikelnummer här</div>

        {/* categories */}
        {isError ? (
          <p>Failed to load categories</p>
        ) : isLoading ? (
          <p>Loading categories...</p>
        ) : (
          <List
            items={categoryItems}
            className={hasCategories ? 'mb-4 lg:mt-10' : ''}
            listClassName="!grid-cols-1 lg:!grid-cols-2"
          />
        )}

        {/* products inside category */}
        {isErrorProducts ? (
          <p>Failed to load products</p>
        ) : isLoadingProducts ? (
          <p>Loading products...</p>
        ) : (
          hasCategoryProducts && (
            <>
              <h3 className="lg:hidden">{productsResultTitle}</h3>
              <div className="max-lg:hidden lg:mt-10">
                <strong>{productsResultTitle}</strong>
              </div>
              <List className="mt-4" items={categoryProductItems} />
            </>
          )
        )}

        {/* inform user when there are no matching products */}
        {!isError && !isLoading && !isErrorProducts && !isLoadingProducts && !hasCategories && !hasCategoryProducts && (
          <div className="mt-4">No products matching the filter found</div>
        )}
      </div>
    </div>
  );
};
