import { Category } from '../api/queries/use-get-categories';

export const findCategoryById = (categories: Category[], targetId: number): Category | undefined => {
  const stack = [...categories];

  while (stack.length > 0) {
    const category = stack.pop();

    if (category) {
      if (category.id === targetId) {
        return category; // Found the category with the target @id
      }

      if (category.children && category.children.length > 0) {
        // If the category has children, push them onto the stack to search them later
        stack.push(...category.children);
      }
    }
  }
};
