import { LABELS } from '../../assets/LABELS';
import { Image } from '../../components/ui/image/image';
import { List } from '../../components/ui/list/list';
import { useAppContainer } from '../components/use-app-container';
import './info-page.scss';

export const InfoPage = () => {
  useAppContainer({ scrollMainToTopOnDeps: [] });

  return (
    <div className="info-page">
      <h1 className="text-center pt-16 lg:hidden">Information</h1>
      <div className="content mx-auto mt-6 mb-5">
        <List
          items={LABELS}
          className="lg:mx-1 mx-4"
          hideDescription={true}
          listClassName="!grid-cols-1 lg:!grid-cols-3"
          extraListItemPadding
          centerText
          customImageRenderer={(item) => (
            <Image
              hideOnError={true}
              imageProps={{
                src: item.imageSrc,
                className: `object-contain my-4 ${item.imageUseFullWidth ? 'max-w-[324px] w-full' : 96}`,
                height: 96,
                loading: 'lazy',
                alt: item.imageAlt,
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
