import DOMPurify from 'dompurify';
import { useLayoutEffect, useState } from 'react';

interface SanitizeHtmlProps {
  unsanitizedHtml: string;
  className?: string;
}

// sanitizes and renders unsanitizedHtml
export const SanitizedHtml = ({ unsanitizedHtml, className = '' }: SanitizeHtmlProps) => {
  const [sanitizedHtml, setSanitizedHtml] = useState<string>('');

  useLayoutEffect(() => {
    setSanitizedHtml(DOMPurify.sanitize(unsanitizedHtml));
  }, [unsanitizedHtml]);

  return <span className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
