import { useState } from 'react';
import './dropdown.scss';

interface DropdownProps<T> {
  options: {
    key: T;
    label: string;
  }[];
  value?: T | ''; // value can be an empty string
  className?: string;
  onToggle: (key: T) => void;
  placeholder?: string;
}

export const Dropdown = <T extends string>({
  options,
  className,
  value = '',
  placeholder = '',
  onToggle,
}: DropdownProps<T>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => setIsFocused(false);

  const labelClass = !value && !isFocused ? 'inside-input' : '';

  return (
    <div
      className={`dropdown ${className} relative lg:mt-5 lg:pt-0.5 ${labelClass !== 'inside-input' && 'mt-5 pt-0.5'}`}
    >
      <label className={`mb-1.5 block h-4 ${labelClass}`}>{placeholder}</label>
      <select
        defaultValue=""
        value={value}
        onChange={(e) => onToggle(e.target.value as T)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`w-full ${value ? 'px-2 py-3 selected' : 'empty px-4 py-3'}`}
        style={{ minHeight: 47 }}
      >
        <option value="" disabled className="hidden"></option>
        {value && <option value="">Inget val</option>}
        {options.map(({ key, label }) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
