import Fuse from 'fuse.js';
import {
  BastaBetaStatus,
  BvbStatus,
  GreenBuildingRating,
  HealthyHousesRating,
  getFilterOfBastaBetaStatus,
  getFilterOfBvbStatus,
  getFilterOfGreenBuildingRating,
  getFilterOfHealthyHousesRating,
} from '../api/enums';
import { Article, Product, useGetProducts } from '../api/queries/use-get-products';
import { HydraMember } from '../api/type';
import { FilterKeysByValueType } from './types';
import { unique } from './unqiue';

export type Label = 'svanens-husportal' | 'emissionsmätning';

type UseFilteredProducts = (props: {
  filter: {
    searchValue?: string;
    indicator13Rating?: GreenBuildingRating;
    indicator14Rating?: GreenBuildingRating;
    bvbTotalStatus?: BvbStatus;
    bvbLifecycleStatus?: BvbStatus;
    bvbContentStatus?: BvbStatus;
    bastaBetaStatus?: BastaBetaStatus;
    healthyHousesRating?: HealthyHousesRating;
    activeLabels?: Label[];
  };
}) => { filteredProducts?: Product[]; isLoading: boolean; error: unknown; isError: boolean };

type StringKeysOfProductOrArticle = FilterKeysByValueType<Product, string> | FilterKeysByValueType<Article, string>;

export const useFilteredProducts: UseFilteredProducts = ({ filter }) => {
  const { data, isLoading, error, isError } = useGetProducts();

  let filteredProducts = (data?.['hydra:member'] ?? []).filter(
    ({
      greenBuildingIndicator13,
      greenBuildingIndicator14,
      bvbTotal,
      bvbLifecycle,
      bvbContents,
      healthyHousesRating,
      svanensHousePortal,
      bastaBetaStatus,
    }) => {
      const matchesIndicator13Rating =
        !filter.indicator13Rating ||
        getFilterOfGreenBuildingRating(filter.indicator13Rating).includes(greenBuildingIndicator13);
      if (!matchesIndicator13Rating) return false;

      const matchesIndicator14Rating =
        !filter.indicator14Rating ||
        getFilterOfGreenBuildingRating(filter.indicator14Rating).includes(greenBuildingIndicator14);
      if (!matchesIndicator14Rating) return false;

      const matchesBvbTotalStatus =
        !filter.bvbTotalStatus || getFilterOfBvbStatus(filter.bvbTotalStatus).includes(bvbTotal);
      if (!matchesBvbTotalStatus) return false;

      const matchesBvbLifecycleStatus =
        !filter.bvbLifecycleStatus || getFilterOfBvbStatus(filter.bvbLifecycleStatus).includes(bvbLifecycle);
      if (!matchesBvbLifecycleStatus) return false;

      const matchesBvbContentStatus =
        !filter.bvbContentStatus || getFilterOfBvbStatus(filter.bvbContentStatus).includes(bvbContents);
      if (!matchesBvbContentStatus) return false;

      const matchesBastaStatus =
        !filter.bastaBetaStatus || getFilterOfBastaBetaStatus(filter.bastaBetaStatus).includes(bastaBetaStatus);
      if (!matchesBastaStatus) return false;

      const matchesHealthyHousesRating =
        !filter.healthyHousesRating ||
        getFilterOfHealthyHousesRating(filter.healthyHousesRating).includes(healthyHousesRating);
      if (!matchesHealthyHousesRating) return false;

      // label "svanens-husportal" maps to "svanensHousePortal" (bool) property (only check if filter is enabled)
      const hassvanensHousePortalFilter = filter.activeLabels?.includes('svanens-husportal');
      const matchessvanensHousePortalFilter = !hassvanensHousePortalFilter || svanensHousePortal;
      if (!matchessvanensHousePortalFilter) return false;

      return true;
    },
  );

  if (filter.searchValue) {
    // numeric search values can have spaces between numbers
    const isSearchValueNumeric = /^[0-9 ]+$/.test(filter.searchValue);
    const searchValue = isSearchValueNumeric
      ? // remove spaces in numeric search values
        filter.searchValue.replace(/ /g, '')
      : filter.searchValue ?? '';

    const fuse = new Fuse(
      // create a flat array with items for each article to make articles searchable by their article number
      filteredProducts.flatMap((product) => [
        // add a searchable item per article
        ...product.articles.map(({ articleNumber, europeanArticleNumber }) => ({
          ...product,
          articleNumber,
          europeanArticleNumber,
        })),
        // add the product itself as a searchable item to allow searching for products without articles
        product,
      ]),
      {
        keys: [
          { name: 'name', weight: 3 },
          { name: 'articleNumber', weight: 1 },
          { name: 'europeanArticleNumber', weight: 1 },
        ] satisfies { name: StringKeysOfProductOrArticle; weight: number }[],
        shouldSort: true,
        // Run an exact search when the value is numeric and more then 5 chars.
        // - The numeric check allows to not find products with similiar article numbers when only searching for an article number.
        // - The 5 char-check allows the search of "521" to still get "SikaFlex 521".
        threshold: isSearchValueNumeric && searchValue.length > 5 ? 0 : 0.4,
      },
    );

    filteredProducts = unique<HydraMember<Product>>(
      fuse.search(searchValue).map(({ item }) => item),
      'id',
    );
  }

  return {
    filteredProducts,
    isLoading,
    error,
    isError,
  };
};
