import Quagga, {
  QuaggaJSConfigObject,
  QuaggaJSResultCallbackFunction,
  QuaggaJSResultObject_CodeResult,
} from '@ericblade/quagga2';
import { RefObject, useCallback, useLayoutEffect } from 'react';

function getMedian(arr: (number | undefined)[]) {
  arr.sort((a, b) => (a ?? 0) - (b ?? 0));
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return ((arr[half - 1] ?? 0) + (arr[half] ?? 0)) / 2;
}

function getMedianOfCodeErrors(decodedCodes: QuaggaJSResultObject_CodeResult['decodedCodes']) {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultLocatorSettings = {
  patchSize: 'medium',
  halfSample: true,
};

const defaultDecoders = ['ean_reader'];

interface BarcodeScannerProps {
  onDetected: (result: string) => void;
  scannerRef: RefObject<HTMLDivElement>;
  onScannerReady?: () => void;
  cameraId?: string;
  facingMode?: string;
  locator?: QuaggaJSConfigObject['locator'];
  numOfWorkers?: number;
  decoders?: string[];
  locate?: boolean;
  setCameraError: (err: unknown) => void;
}

export const BarcodeScanner = ({
  onDetected,
  scannerRef,
  onScannerReady,
  cameraId,
  facingMode,
  locator = defaultLocatorSettings,
  numOfWorkers = navigator.hardwareConcurrency || 0,
  decoders = defaultDecoders,
  locate = true,
  setCameraError,
}: BarcodeScannerProps) => {
  const errorCheck = useCallback<QuaggaJSResultCallbackFunction>(
    (result) => {
      if (!onDetected) {
        return;
      }
      const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
      if (err && err < 0.25 && result.codeResult.code) {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected],
  );

  useLayoutEffect(() => {
    void Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          size: 1920,
          constraints: {
            width: {
              ideal: 1920,
              min: 640,
            },
            height: {
              ideal: 1080,
              min: 480,
            },
            aspectRatio: 1080 / 1920,
            // @ts-expect-error unknown property
            focusMode: 'continuous',
            ...(cameraId && { deviceId: cameraId }),
            ...(!cameraId && { facingMode }),
          },
          target: scannerRef.current!,
        },
        locator: {
          patchSize: 'medium',
          halfSample: true,
        },
        numOfWorkers,
        frequency: 10,
        decoder: { readers: ['ean_reader'] },
        locate: true,
      },
      (err) => {
        if (err) {
          setCameraError(err);

          return console.log('Error starting Quagga:', err);
        }
        if (scannerRef?.current) {
          Quagga.start();

          setCameraError(null);
          if (onScannerReady) {
            onScannerReady();
          }
        }
      },
    );

    Quagga.onDetected(errorCheck);
    return () => {
      Quagga.offDetected(errorCheck);
      void Quagga.stop();
      setCameraError(null);
    };
  }, [
    cameraId,
    onDetected,
    onScannerReady,
    scannerRef,
    errorCheck,
    locator,
    decoders,
    locate,
    facingMode,
    numOfWorkers,
    setCameraError,
  ]);

  return null;
};
