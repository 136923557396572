import { useQuery } from '@tanstack/react-query';
import { extractURLId } from '../../utils/extract-url-id';
import { httpFetch } from '../../utils/http-fetch';
import { HydraMember, HydraMultiResponse } from '../type';

type Response = HydraMultiResponse<Category>;

export const CATEGORIES_QUERY_KEY = 'categories';

export const getCategories = async () => {
  const categories = await httpFetch<Response>(`/api/categories.jsonld`);
  addIdField(categories);

  return categories;
};

export const useGetCategories = () => {
  return useQuery<Response>({ queryKey: [CATEGORIES_QUERY_KEY], queryFn: getCategories });
};

export interface Category {
  id: number;
  name: string;
  children: Category[];
}

function addIdField(categories: Response) {
  const hydraMember = [...categories['hydra:member']];

  while (hydraMember.length > 0) {
    const category = hydraMember.pop();

    if (category) {
      // Add the id field
      category.id = extractURLId(category['@id']);

      // If the node has children, add them to the stack
      if (category.children && category.children.length > 0) {
        hydraMember.push(...(category.children as HydraMember<Category>[]));
      }
    }
  }
}
