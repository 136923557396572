import { SVGProps } from 'react';
import { Icon } from './type';

export const LinksIcon: Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Links" clipPath="url(#clip0_8_2015)">
      <path
        id="Vector"
        d="M0 5.57853C0.0907881 5.28891 0.161749 4.99094 0.27706 4.71124C0.473768 4.23323 0.782656 3.8335 1.1839 3.50109C1.56636 3.18434 1.99003 2.95629 2.47215 2.84358C2.69286 2.79191 2.91931 2.73399 3.14315 2.73242C4.67924 2.72147 6.21533 2.71886 7.75091 2.72773C8.20224 2.73034 8.56591 2.9349 8.76366 3.35289C8.97029 3.78967 8.89828 4.21601 8.58991 4.57712C8.38851 4.813 8.1078 4.94659 7.78743 4.94763C6.30351 4.95076 4.8196 4.94763 3.33568 4.95024C2.80504 4.95128 2.42415 5.2409 2.26136 5.757C2.24101 5.82171 2.22796 5.89216 2.22796 5.96C2.2264 9.7355 2.22692 13.5105 2.2264 17.286C2.2264 17.7478 2.74243 18.2738 3.21307 18.2738C6.94216 18.2744 10.6718 18.2764 14.4009 18.2759C15.0667 18.2759 15.5409 17.8042 15.5462 17.1399C15.5576 15.6365 15.5389 14.1325 15.5519 12.6291C15.555 12.2622 15.758 11.9742 16.0768 11.7785C16.6779 11.409 17.508 11.7258 17.7011 12.3645C17.7392 12.4908 17.77 12.6249 17.7705 12.7559C17.7752 14.24 17.8023 15.7246 17.7637 17.2082C17.7319 18.4403 17.1673 19.4005 16.0987 20.0476C15.721 20.2766 15.3129 20.4045 14.8793 20.465C14.8653 20.4671 14.8543 20.488 14.8418 20.5H2.85096C2.83896 20.4875 2.828 20.4656 2.81391 20.4635C1.70149 20.2777 0.874488 19.6864 0.364196 18.6845C0.199838 18.361 0.118964 17.9952 0 17.6487C0 13.6253 0 9.60191 0 5.57853Z"
      />
      <path
        id="Vector_2"
        d="M16.1483 2.72356C14.8334 2.72356 13.5191 2.7199 12.2042 2.72512C11.5708 2.72773 11.0689 2.18658 11.097 1.57603C11.12 1.08342 11.4226 0.613761 11.9997 0.540182C12.1515 0.520874 12.3049 0.502087 12.4573 0.502087C14.5731 0.5 16.6888 0.501044 18.8041 0.5C19.2481 0.5 19.6061 0.673251 19.8242 1.05889C19.9228 1.23318 19.989 1.45236 19.9901 1.6517C20.0016 3.81942 20.0052 5.98766 19.9922 8.15538C19.9885 8.74819 19.7866 9.13748 19.1782 9.34569C18.4597 9.59148 17.7653 8.96997 17.7705 8.29471C17.7804 7.01881 17.7731 5.74292 17.7731 4.46702C17.7731 4.42371 17.7731 4.38039 17.7731 4.28803C17.6865 4.36578 17.6296 4.41171 17.5785 4.46285C14.5632 7.47751 11.5468 10.4911 8.53618 13.5105C8.19963 13.8481 7.66638 13.9071 7.30532 13.7406C6.61762 13.4239 6.40683 12.5117 6.98704 11.9314C9.99609 8.91987 13.0067 5.90938 16.0173 2.89942C16.0664 2.85036 16.1232 2.81018 16.1765 2.7653C16.1671 2.75121 16.1582 2.73765 16.1488 2.72356H16.1483Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_2015">
        <rect width="20" height="20" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
