export enum BastaBetaStatus {
  BASTA = 'basta',
  BETA = 'beta',
  BASTA_BETA = 'basta_beta',
  DECLARED = 'declared',
  DECLARED_BASTA = 'declared_basta',
}

export const LABELED_BASTA_STATUS = [
  {
    key: BastaBetaStatus.BASTA,
    label: 'BASTA',
  },
  {
    key: BastaBetaStatus.BETA,
    label: 'BETA',
  },
  {
    key: BastaBetaStatus.BASTA_BETA,
    label: 'BETA → BASTA',
  },
  {
    key: BastaBetaStatus.DECLARED,
    label: 'DEKLARERAD',
  },
  {
    key: BastaBetaStatus.DECLARED_BASTA,
    label: 'DEKLARERAD → BASTA',
  },
];

const RANKED_BASTA_STATUS = [
  BastaBetaStatus.BASTA,
  BastaBetaStatus.BETA,
  BastaBetaStatus.BASTA_BETA,
  BastaBetaStatus.DECLARED,
  BastaBetaStatus.DECLARED_BASTA,
];

// When filtering we want to include all statuses up to and including the selected status
export const getFilterOfBastaBetaStatus = (bastaBetaStatus: BastaBetaStatus): BastaBetaStatus[] => {
  return RANKED_BASTA_STATUS.slice(0, RANKED_BASTA_STATUS.indexOf(bastaBetaStatus) + 1);
};

export const toLabeledBastaStatus = (bastaStatus?: BastaBetaStatus): string | undefined => {
  return LABELED_BASTA_STATUS.find(({ key }) => key === bastaStatus)?.label;
};
