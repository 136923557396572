export enum GreenBuildingRating {
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze',
}

export const LABELED_GREEN_BUILDING_RATING = [
  {
    key: GreenBuildingRating.GOLD,
    label: 'Guld',
  },
  {
    key: GreenBuildingRating.SILVER,
    label: 'Silver',
  },
  {
    key: GreenBuildingRating.BRONZE,
    label: 'Brons',
  },
];

const RANKED_GREEN_BUILDING_RATING = [GreenBuildingRating.GOLD, GreenBuildingRating.SILVER, GreenBuildingRating.BRONZE];

// When filtering we want to include all statuses up to and including the selected status
export const getFilterOfGreenBuildingRating = (greenBuildingRating: GreenBuildingRating): GreenBuildingRating[] => {
  return RANKED_GREEN_BUILDING_RATING.slice(0, RANKED_GREEN_BUILDING_RATING.indexOf(greenBuildingRating) + 1);
};

export const toLabeledGreenBuildingRating = (greenBuildingRating?: GreenBuildingRating): string | undefined => {
  return LABELED_GREEN_BUILDING_RATING.find(({ key }) => key === greenBuildingRating)?.label;
};
