import { MailIcon } from '../components/ui/icons/mail-icon';
import { Item } from '../components/ui/list/list-item';

export const CONTACT: Item[] = [
  {
    key: 'övriga-frågor',
    title: 'Övriga frågor',
    description: `Kontakta oss om du har frågor, kommentarer eller funderingar om Miljöappen`,
    mail: 'miljo@se.sika.com',
    Icon: MailIcon,
  },
  {
    key: 'miljörelaterade-frågor',
    title: 'Miljörelaterade frågor',
    description: `Kontakta oss om du har miljörelaterade frågor om produkterna`,
    mail: 'marknad@se.sika.com',
    Icon: MailIcon,
  },
];
