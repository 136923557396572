import { HydraError } from '../api/hydra-error';
import { HydraErrorResponse } from '../api/type';

interface HttpFetchOptions {
  init?: RequestInit;
  parseJsonResponse?: boolean;
}

export const httpFetch = async <T>(
  url: string,
  options: HttpFetchOptions | undefined = {
    init: {},
    parseJsonResponse: true,
  },
): Promise<T> => {
  const response = await fetch(import.meta.env.VITE_BACKEND_HOST + url, options.init);

  if (!response.ok) {
    // parse hydra error json
    const error = new HydraError((await response.json()) as HydraErrorResponse);
    console.error('A http response was not ok', { error });
    throw error;
  }

  return await ((options.parseJsonResponse ? response.json() : response.text()) as Promise<T>);
};
