const PREVIOUSLY_USED_LOCALSTORAGE_KEYS = [
  'local-products-schema-version',
  'cached-products',
  'local-categories-schema-version',
  'cached-categories',
];

export const removePreviouslyUsedLocalstorageEntries = () => {
  PREVIOUSLY_USED_LOCALSTORAGE_KEYS.forEach((key) => localStorage.removeItem(key));
};
