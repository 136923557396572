import { useParams } from 'react-router-dom';

import { toLabeledDocumentType } from '../../api/enums/DocumentType';
import { PRIMARY_IMAGE_TYPE } from '../../api/enums/ImageType';
import { useGetProducts } from '../../api/queries/use-get-products';
import { BackButton } from '../../components/back-button';
import { PathBreadcrumbs } from '../../components/hierarchy-breadcrumbs/path-breadcrumbs';
import { DownloadList } from '../../components/ui/download-list/download-list';
import { Image } from '../../components/ui/image/image';
import { Tabs } from '../../components/ui/tabs/tabs';
import { TextWithBreaks } from '../../components/ui/text-with-breaks/text-with-breaks';
import { SanitizedHtml } from '../../utils/sanitized-html';
import { useNavigateUp } from '../../utils/use-navigate-up';
import { useAppContainer } from '../components/use-app-container';
import './product-page.scss';
import { EnvironmentTabContent } from './tabs/environment-tab-content';
import { ProductInformationTabContent } from './tabs/product-information-tab-content';

export const ProductPage = () => {
  const { id: idParam = '' } = useParams();
  const { data, isLoading, isError } = useGetProducts();

  // scroll to top when a new product loads
  useAppContainer({ scrollMainToTopOnDeps: [idParam] });

  const navigateUp = useNavigateUp();

  if (!idParam) {
    console.warn('Reached product page without id search param set');
    navigateUp();
  }

  let id: number;
  try {
    id = parseInt(idParam);
  } catch (error) {
    console.error('Failed to parse id search param', { idParam });
    navigateUp();
  }

  const product = data?.['hydra:member']?.find((p) => p.id === id);

  const { name, description, shortDescription, images, documents } = product ?? {};

  const image = images?.find(({ type }) => type === PRIMARY_IMAGE_TYPE);
  const { imageUrl, altText } = image ?? {};

  return (
    <div className="product-page">
      <div className="mt-4 mb-5">
        <div className="max-lg:hidden center-container">
          <PathBreadcrumbs className="mt-4 mb-7" />
          <BackButton className="max-lg:mx-5" />
        </div>
        {isLoading ? (
          <div className="center-container text-small-desktop">Loading product...</div>
        ) : isError ? (
          <div className="center-container text-small-desktop">Failed to load product</div>
        ) : (
          <>
            <div className="pb-6 lg:py-12 max-lg:px-5 center-container flex gap-x-8">
              {imageUrl && (
                <Image
                  hideOnError={true}
                  imageProps={{ src: imageUrl, alt: altText, className: 'max-lg:hidden object-contain lg:w-64' }}
                />
              )}
              <div className="w-full">
                <div className="flex justify-between gap-x-8 items-center max-lg:items-stretch w-full">
                  <div className="flex flex-col grow max-lg:justify-between max-lg:gap-y-2">
                    <BackButton className="lg:hidden" />
                    <div className="grow flex items-center">
                      <h1 className="text-light lg:hidden">
                        <TextWithBreaks>{name ?? ''}</TextWithBreaks>
                      </h1>
                      <h4 className="text-light max-lg:hidden">
                        <TextWithBreaks>{name ?? ''}</TextWithBreaks>
                      </h4>
                    </div>
                  </div>
                  <div className="grow-0 flex items-center justify-end">
                    <div className="max-w-48 max-h-48">
                      {imageUrl && (
                        <Image
                          hideOnError={true}
                          imageProps={{
                            src: imageUrl,
                            alt: altText,
                            className: 'lg:hidden object-contain w-full max-w-48 max-h-48 h-auto',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 mt-5 flex-col">
                  {!!description && (
                    <span className="text-small-desktop">
                      <SanitizedHtml unsanitizedHtml={description} />
                    </span>
                  )}
                  {!!shortDescription && <span className="text-small-desktop">{shortDescription}</span>}
                </div>
              </div>
            </div>
            <Tabs
              tabs={[
                {
                  key: 'miljöbedömningar',
                  title: 'Miljöbedömningar',
                  content: (
                    <div className="center-container max-lg:px-5">
                      <EnvironmentTabContent product={product} />
                    </div>
                  ),
                },
                {
                  key: 'produktinformation',
                  title: 'Produktinformation',
                  content: (
                    <div className="center-container max-lg:px-5">
                      <ProductInformationTabContent product={product} />
                    </div>
                  ),
                },
                {
                  key: 'dokument',
                  title: 'Dokument',
                  content: (
                    <div className="center-container max-lg:px-5">
                      {documents && documents?.length > 0 ? (
                        <DownloadList
                          items={(documents ?? []).map(({ id, title, type, url }) => ({
                            key: `${id}`,
                            src: url,
                            subtitle: toLabeledDocumentType(type),
                            title,
                          }))}
                        />
                      ) : (
                        'No documents available'
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};
