import { SanitizedHtml } from '../../../utils/sanitized-html';
import './striped-rows.scss';

interface StripedRowsProps {
  rows: Partial<{
    key: string;
    title: string;
    text: string;
    asHtml?: boolean;
  }>[];
  className?: string;
}

export const StripedRows = ({ rows, className }: StripedRowsProps) => {
  return (
    <div className={`striped-rows ${className}`}>
      {rows.map(({ key, text, title, asHtml = false }) => (
        <div key={key} className="py-2 px-5 row flex gap-4 justify-between text-small-desktop overflow-x-auto">
          <span>{title}</span>
          {!!text && (
            <strong className="text-right table-wrapper">
              {asHtml ? <SanitizedHtml unsanitizedHtml={text} /> : text}
            </strong>
          )}
        </div>
      ))}
    </div>
  );
};
