import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BarcodeScanner } from './barcode-scanner';
import './scanner.css';

interface ScannerProps {
  onResult: (code: string) => void;
}

export const Scanner = ({ onResult }: ScannerProps) => {
  const [scanning, setScanning] = useState(false);
  const [cameraError, setCameraError] = useState<unknown>(null);
  const scannerRef = useRef(null);

  useEffect(() => {
    // delay start to prevent camera from freezing
    setTimeout(() => {
      setScanning(true);
    }, 500);
  }, []);

  return (
    <>
      <span className="font-light text-sm pb-4 block text-center">
        Scanner not starting?{' '}
        <a
          className="text-blue-600"
          href="#"
          onClick={() => {
            setScanning(!scanning);
            setTimeout(() => {
              setScanning(true);
            }, 500);
          }}
        >
          Click here to restart camera
        </a>
        .
      </span>
      <ErrorBoundary fallback={<div>Unknown camera error</div>}>
        <>
          {cameraError &&
            `Camera error: ${cameraError && typeof cameraError === 'object' && 'message' in cameraError && typeof cameraError.message === 'string' ? cameraError.message : 'Unknown error'}`}
        </>
      </ErrorBoundary>
      <div className="scanner" ref={scannerRef} style={{ position: 'relative' }}>
        <canvas
          className="drawingBuffer"
          style={{
            position: 'absolute',
            top: '0px',
          }}
          width="480px"
          height="640px"
        />
        {scanning ? (
          <BarcodeScanner setCameraError={setCameraError} scannerRef={scannerRef} onDetected={onResult} />
        ) : null}
      </div>
    </>
  );
};
