import { NavLink } from 'react-router-dom';
import { useResolvedPath } from '../../utils/use-path';
import { PATHS } from './PATHS';
import './desktop-navigation.scss';
import './navigation.scss';

interface DesktopNavigationProps {
  className?: string;
}

export const DesktopNavigation = ({ className }: DesktopNavigationProps) => {
  const { navigationTitle } = useResolvedPath();

  return (
    <div className={`desktop navigation z-10 ${navigationTitle && 'grey-background'} ${className}`}>
      {/* use negative margin to make navigation appear on top of header */}
      <nav className="mx-auto -mt-6">
        <ul className="m-0 px-7 py-5 grid grid-flow-col justify-start">
          {PATHS.map(({ to, text }) => (
            <NavLink
              key={to}
              to={to}
              className={({ isActive }) =>
                `first:pl-0 last:pr-0 px-4 py-2 cursor-pointer text-inherit no-underline ${isActive && 'font-bold'}`
              }
            >
              {text}
            </NavLink>
          ))}
        </ul>
        {navigationTitle && <h3 className="text-light mt-9 mb-10">{navigationTitle}</h3>}
      </nav>
    </div>
  );
};
