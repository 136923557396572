import { Category } from '../api/queries/use-get-categories';
import { Product } from '../api/queries/use-get-products';

// Gets all nested products of a category as a list
export const getAllNestedProducts = (allProducts: Product[], targetCategory: Category): Product[] => {
  const result: Product[] = [];
  const stack: Category[] = [];

  // Start by pushing the target category onto the stack
  stack.push(targetCategory);

  while (stack.length > 0) {
    const currentCategory = stack.pop();

    if (currentCategory) {
      // Find all products that belong to the current category
      const productsInCurrentCategory = allProducts.filter((product) =>
        product.categories.includes(currentCategory.id),
      );

      // Add these products to the result
      result.push(...productsInCurrentCategory);

      // Find all child categories of the current category
      const childCategories = currentCategory.children || [];

      // Push child categories onto the stack for further exploration
      stack.push(...childCategories);
    }
  }

  return result;
};
