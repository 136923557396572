import { PRIMARY_IMAGE_TYPE } from '../../api/enums/ImageType';
import { BackButton } from '../../components/back-button';
import { PathBreadcrumbs } from '../../components/hierarchy-breadcrumbs/path-breadcrumbs';
import { List } from '../../components/ui/list/list';
import { Item } from '../../components/ui/list/list-item';
import { TextInput } from '../../components/ui/text-input/text-input';
import { useFilteredProducts } from '../../utils/use-filtered-products';
import { useSearchParamValue } from '../../utils/use-search-param-value';
import { useAppContainer } from '../components/use-app-container';
import './name-search-page.scss';

export const NameSearchPage = () => {
  useAppContainer({ scrollMainToTopOnDeps: [] });

  const [searchValue, setSearchValue] = useSearchParamValue('value', '');
  const { isError, isLoading, filteredProducts } = useFilteredProducts({
    filter: { searchValue },
  });

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const {
      target: { value },
    } = e;
    setSearchValue(value);
  };

  const listItems: Item[] = !filteredProducts
    ? []
    : filteredProducts.map(({ id, name, images }) => {
        const { altText, imageUrl } = images?.find(({ type }) => type === PRIMARY_IMAGE_TYPE) ?? {};

        return {
          key: id,
          title: name,
          link: `product/${id}`,
          imageSrc: imageUrl,
          imageAlt: altText,
        };
      });

  const resultTitle = `${searchValue} (${filteredProducts?.length ?? 0})`;

  return (
    <div className="name-search-page">
      <div className="content mx-auto mt-4 mb-5 max-lg:px-5">
        <PathBreadcrumbs className="mt-4 mb-7" />
        <BackButton />
        <h1 className="text-center pt-5 lg:hidden">Namn/Artikelnummer/EAN</h1>
        <div className="text-center pt-3 lg:hidden">Ange ditt namn eller artikelnummer här</div>
        <TextInput
          inputProps={{
            onChange: onSearchChange,
            value: searchValue,
            placeholder: 'Namn/Artikelnummer/EAN',
            autoFocus: true,
          }}
          floatPlaceholder={false}
          autoSelect={true}
          fillBackground={true}
          className="pt-6 lg:pt-10"
        />
        {isLoading ? (
          'Loading products...'
        ) : isError ? (
          'Failed to load products'
        ) : (
          <>
            <h3 className="mt-4 lg:hidden whitespace-pre">{resultTitle}</h3>
            <div className="mt-4 max-lg:hidden whitespace-pre">
              <strong>{resultTitle}</strong>
            </div>
            <List items={listItems} className="mt-4" />
          </>
        )}
      </div>
    </div>
  );
};
