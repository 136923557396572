import { HydraErrorResponse } from './type';

export class HydraError extends Error {
  public context: string;
  public type: string;
  public title: string;
  public description: string;
  public trace: {
    namespace: string;
    short_class: string;
    class: string;
    type: string;
    function: string;
    file: string;
    line: number;
    args: string[];
  }[];

  constructor(errorData: HydraErrorResponse) {
    super(errorData['hydra:title']);

    // set the error properties
    this.context = errorData['@context'];
    this.type = errorData['@type'];
    this.title = errorData['hydra:title'];
    this.description = errorData['hydra:description'];
    this.trace = errorData.trace;

    // ensure the error prototype is correctly set
    Object.setPrototypeOf(this, HydraError.prototype);
  }
}
