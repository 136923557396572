import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { Item, ListItem } from './list-item';
import './list.scss';

const PAGE_SIZE = 20;

interface ListProps {
  items: Item[];
  className?: string;
  hideDescription?: boolean;
  hideImage?: boolean;
  lightHeader?: boolean;
  fillBgOnHover?: boolean;
  customListItemRenderer?: (item: Item) => React.JSX.Element;
  customImageRenderer?: (item: Item) => React.JSX.Element;
  listClassName?: string;
  extraListItemPadding?: boolean;
  centerText?: boolean;
}

export const List = ({
  items,
  className,
  hideDescription = false,
  hideImage = false,
  lightHeader = false,
  fillBgOnHover = false,
  listClassName = '',
  extraListItemPadding = false,
  centerText = false,
  customImageRenderer,
  customListItemRenderer,
}: ListProps) => {
  const navigate = useNavigate();
  const [loadedPages, setLoadedPages] = useState<number>(1);

  const sendEmail = (mail: string) => {
    window.open(`mailto:${mail}`, '_blank');
  };

  const loadedItems = items.slice(0, loadedPages * PAGE_SIZE);

  const loadMore = () => setLoadedPages((n) => n + 1);

  return (
    <div className={`list ${className}`}>
      <InfiniteScroll
        dataLength={loadedItems.length}
        next={loadMore}
        hasMore={loadedItems.length < items.length}
        loader={<>Loading...</>}
        scrollableTarget="main"
      >
        <ul
          className={`my-1.5 pl-0 grid gap-x-5 gap-y-4 lg:gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ${listClassName}`}
        >
          {loadedItems.map((item, i) => {
            const { key, title, link, mail } = item;

            const onClick = () => {
              if (item.onClick) {
                item.onClick(item);
              }
              if (link) {
                if (link.startsWith('http')) {
                  window.open(link, '_blank');
                } else {
                  navigate(`${link}${location.search}`);
                }
              }
              if (mail) {
                sendEmail(mail);
              }
            };

            return (
              <ListItem
                customListItemRenderer={customListItemRenderer}
                key={key ?? title ?? i}
                item={item}
                lightHeader={lightHeader}
                hideDescription={hideDescription}
                hideImage={hideImage}
                onClick={onClick}
                fillBgOnHover={fillBgOnHover}
                extraListItemPadding={extraListItemPadding}
                customImageRenderer={customImageRenderer}
                centerText={centerText}
              />
            );
          })}
        </ul>
      </InfiniteScroll>
    </div>
  );
};
