import { Link } from 'react-router-dom';
import './header.scss';
import './mobile-header.scss';

interface MobileHeaderProps {
  className?: string;
}

export const MobileHeader = ({ className }: MobileHeaderProps) => {
  return (
    <div className={`px-5 py-2 mobile header flex justify-between items-center ${className}`}>
      <Link to="/">
        <img src="/logo.svg" />
      </Link>
      <Link to="/" className="text-inherit no-underline">
        <h2>MILJÖAPPEN</h2>
      </Link>
      {/* second invisible logo on the right side to make the title appear centered */}
      <img className="invisible" src="/logo.svg" />
    </div>
  );
};
