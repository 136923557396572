import { useResolvedPath } from '../../utils/use-path';
import { Breadcrumbs } from '../ui/breadcrumbs/breadcrumbs';

interface PathBreadcrumbsProps {
  className?: string;
}

export const PathBreadcrumbs = ({ className }: PathBreadcrumbsProps) => {
  const { path } = useResolvedPath();

  return (
    <Breadcrumbs
      className={`max-lg:hidden ${className}`}
      items={path.map(({ title, link }) => ({
        title,
        key: title,
        link,
      }))}
    />
  );
};
