import { findCategoryById } from '../../utils/find-category-by-id';
import { PathSegmentResolver, ResolvedPathSegment } from '../../utils/use-path';

export const resolveCategoryPath: PathSegmentResolver = ({
  categories,
  defaultLink,
  nextPathSegment: categoryIdsText,
}) => {
  const resolvedCategoryPathname: ResolvedPathSegment = {
    title: 'Kategori',
    link: defaultLink + location.search, // add search path to keep filters
    isPageTitle: true,
    hidePageTitle: true,
  };

  if (categoryIdsText && categories.length > 0) {
    // path is like "/category/{categoryIdText}", where categoryIdText is e.g. "45-42-133-2"
    // return one path segment for each selected category
    const categoryIds = categoryIdsText?.split('-') ?? [];
    return [
      resolvedCategoryPathname,
      ...categoryIds.map((categoryIdText, i) => {
        const categoryId = parseInt(categoryIdText);
        const category = findCategoryById(categories, categoryId);
        if (category) {
          return {
            title: category?.name,
            link: `${defaultLink}/${categoryIds.slice(0, i + 1).join('-')}${location.search}`, // add search path to keep filters
          };
        } else {
          console.warn('Category not found while resolving a path segment', {
            categories,
            categoryId,
            categoryIdText,
            categoryIdsText,
          });
        }
      }),
    ]
      .filter((x) => x)
      .map((x) => x!); // filter out undefined values
  }

  // path is like "/category"
  return resolvedCategoryPathname;
};
