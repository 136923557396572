import './checkbox-input.scss';

interface CheckboxInputProps<T> {
  options: {
    key: T;
    label: string;
  }[];
  value: T[];
  className?: string;
  onToggle: (key: T) => void;
}

export const CheckboxInput = <T extends string>({
  options,
  className,
  value = [],
  onToggle,
}: CheckboxInputProps<T>) => {
  const getToggleHandler = (key: T) => () => onToggle(key);

  return (
    <div className={`checkbox-input flex max-lg:flex-col gap-y-5 gap-x-10 ${className}`}>
      {options.map(({ key, label }) => {
        return (
          <div key={key} className="flex items-center">
            <input
              type="checkbox"
              className="m-0 mr-4 rounded-full"
              onChange={getToggleHandler(key)}
              id={key}
              value={key}
              checked={value.includes(key)}
            />
            <label htmlFor={key}>{label}</label>
          </div>
        );
      })}
    </div>
  );
};
