import { BastaBetaStatus, BvbStatus, GreenBuildingRating, HealthyHousesRating } from '../../api/enums';
import { Label } from '../../utils/use-filtered-products';
import { SearchParam, useSearchParamValue } from '../../utils/use-search-param-value';

type UseFilterSearchParams = () => {
  activeLabelsParam: SearchParam<Label[]>;
  indicator13RatingParam: SearchParam<GreenBuildingRating>;
  indicator14RatingParam: SearchParam<GreenBuildingRating>;
  bvbContentStatusParam: SearchParam<BvbStatus>;
  bvbLifecycleStatusParam: SearchParam<BvbStatus>;
  bvbTotalStatusParam: SearchParam<BvbStatus>;
  healthyHousesRatingParam: SearchParam<HealthyHousesRating>;
  bastaBetaStatusParam: SearchParam<BastaBetaStatus>;
};

export const useFilterSearchParams: UseFilterSearchParams = () => {
  const activeLabelsParam = useSearchParamValue<Label[]>('activeLabels', []);
  const indicator13RatingParam = useSearchParamValue<GreenBuildingRating>('indicator13Rating');
  const indicator14RatingParam = useSearchParamValue<GreenBuildingRating>('indicator14Rating');
  const bvbContentStatusParam = useSearchParamValue<BvbStatus>('bvbContentStatus');
  const bvbLifecycleStatusParam = useSearchParamValue<BvbStatus>('bvbLifecycleStatus');
  const bvbTotalStatusParam = useSearchParamValue<BvbStatus>('bvbTotalStatus');
  const healthyHousesRatingParam = useSearchParamValue<HealthyHousesRating>('healthyHousesRating');
  const bastaBetaStatusParam = useSearchParamValue<BastaBetaStatus>('bastaBetaStatus');

  return {
    activeLabelsParam,
    indicator13RatingParam,
    indicator14RatingParam,
    bvbContentStatusParam,
    bvbLifecycleStatusParam,
    bvbTotalStatusParam,
    healthyHousesRatingParam,
    bastaBetaStatusParam,
  };
};
