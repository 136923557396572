import { PathSegmentResolver } from '../../utils/use-path';

export const resolveProductPath: PathSegmentResolver = ({ products, defaultLink, nextPathSegment: productIdText }) => {
  if (productIdText && products.length > 0) {
    // path is like "/product/{productIdText}"
    const productId = parseInt(productIdText);
    const product = products.find(({ id }) => id === productId);
    if (product) {
      return {
        title: product?.name,
        link: `${defaultLink}/${productId}`,
        hidePageTitle: true,
        isPageTitle: true,
      };
    } else {
      console.warn('Product not found while resolving a path segment', { products, productId, productIdText });
    }
  }

  // path is like "/product"
  return {
    title: 'Produkt',
    link: defaultLink,
  };
};
