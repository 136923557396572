export enum BvbStatus {
  RECOMMENDED = 'recommended',
  ACCEPTED = 'accepted',
  AVOIDED = 'avoided',
}

export const LABELED_BVB_STATUS = [
  {
    key: BvbStatus.RECOMMENDED,
    label: 'Rekommenderas',
  },
  {
    key: BvbStatus.ACCEPTED,
    label: 'Accepteras',
  },
  {
    key: BvbStatus.AVOIDED,
    label: 'Undviks',
  },
];

const RANKED_BVB_STATUS = [BvbStatus.RECOMMENDED, BvbStatus.ACCEPTED, BvbStatus.AVOIDED];

// When filtering we want to include all statuses up to and including the selected status
export const getFilterOfBvbStatus = (bvbStatus: BvbStatus): BvbStatus[] => {
  return RANKED_BVB_STATUS.slice(0, RANKED_BVB_STATUS.indexOf(bvbStatus) + 1);
};

export const toLabeledBvbStatus = (bvbStatus?: BvbStatus): string | undefined => {
  return LABELED_BVB_STATUS.find(({ key }) => key === bvbStatus)?.label;
};
