import { DetailedHTMLProps, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Icon } from '../icons/type';
import './text-input.scss';

interface TextInputProps {
  icon?: Icon;
  fillBackground?: boolean;
  autoSelect?: boolean;
  className?: string;
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  floatPlaceholder?: boolean;
}

export const TextInput = (props: TextInputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { icon: IconElement, fillBackground, className, inputProps, autoSelect, floatPlaceholder = true } = props;
  const { placeholder, value } = inputProps ?? {};

  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    if (autoSelect && inputRef.current) {
      inputRef.current.select();
    }
  }, [autoSelect]);

  const labelClass = !value && !isFocused ? 'inside-input' : '';

  return (
    <div className={`text-input relative flex items-center ${className}`}>
      {floatPlaceholder && placeholder && <label className={`mb-1.5 block h-4 ${labelClass}`}>{placeholder}</label>}
      <input
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        type="text"
        className={`px-4 py-3 w-full ${fillBackground ? 'fill-background' : ''}`}
        {...inputProps}
        placeholder={floatPlaceholder ? '' : placeholder}
      />
      {IconElement && <IconElement className="absolute right-3 h-4 pointer-events-none max-lg:hidden" fill="black" />}
    </div>
  );
};
