import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { removePreviouslyUsedLocalstorageEntries } from './api/queries/cleanup';
import { prefetch } from './api/queries/prefetch';
import { LABELS } from './assets/LABELS';
import { queryClient } from './main';
import { CategoryPage } from './pages/category-page/category-page';
import { AppContainer } from './pages/components/app-container';
import { ContactPage } from './pages/contact-page/contact-page';
import { InfoPage } from './pages/info-page/info-page';
import { LabelPage } from './pages/info-page/label-page';
import { LinksPage } from './pages/links-page/links-page';
import { ProductPage } from './pages/product-page/product-page';
import { BarcodeSearchPage } from './pages/search-page/barcode-search-page';
import { FilterSearchPage } from './pages/search-page/filter-search-page';
import { NameSearchPage } from './pages/search-page/name-search-page';
import { SearchPage } from './pages/search-page/search-page';
import { registerServiceWorker } from './utils/register-service-worker';

export const App = () => {
  useEffect(() => {
    removePreviouslyUsedLocalstorageEntries();
  }, []);

  const initServiceWorker = async () => {
    // register "cache first with cache refresh"-type fetch service-worker
    await registerServiceWorker('/fetch-listener.js');

    // prefetch queries once service worker is enabled
    prefetch(queryClient);

    // fetch fallback to route with react-router if offline
    void fetch('/search');
  };
  useEffect(() => {
    void initServiceWorker();
  }, []);

  const productRoute = <Route path="product/:id" element={<ProductPage />} />;

  const categoryRoutes = (
    <Route path="category/:categoryIds?">
      <Route index element={<CategoryPage />} />
      {productRoute}
    </Route>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppContainer />}>
          {/* Route all "/" calls to "/search" */}
          <Route index element={<Navigate to="search" replace />} />

          <Route path="search">
            <Route index element={<SearchPage />} />
            <Route path="name">
              <Route index element={<NameSearchPage />} />
              {productRoute}
            </Route>
            <Route path="barcode">
              <Route index element={<BarcodeSearchPage />} />
              {productRoute}
            </Route>
            <Route path="filter">
              <Route index element={<FilterSearchPage />} />
              {categoryRoutes}
            </Route>
            {categoryRoutes}
          </Route>
          <Route path="info">
            <Route index element={<InfoPage />} />
            {LABELS.map((label) => (
              <Route key={label.key} path={`${label.key}`} element={<LabelPage item={label} />} />
            ))}
          </Route>
          <Route path="contact" element={<ContactPage />} />
          <Route path="links" element={<LinksPage />} />

          {/* Route all other paths to "/search" */}
          <Route path="*" element={<Navigate to="search" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
