import { QueryClient } from '@tanstack/react-query';
import { CATEGORIES_QUERY_KEY, getCategories } from './use-get-categories';
import { PRODUCTS_QUERY_KEY, getProducts } from './use-get-products';

export const prefetch = (queryClient: QueryClient) => {
  console.log('Prefetching queries');
  void queryClient.prefetchQuery({
    queryKey: [PRODUCTS_QUERY_KEY],
    queryFn: getProducts,
  });
  void queryClient.prefetchQuery({
    queryKey: [CATEGORIES_QUERY_KEY],
    queryFn: getCategories,
  });
};
