type UpdateAppFn = () => Promise<void>;

const getLocalStorageVersionKey = () => {
  try {
    const getDisplayMode = () => {
      // @ts-expect-error window.navigator?.standalone used for iOS
      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator?.standalone) {
        return 'standalone';
      }
      if (window.matchMedia('(display-mode: fullscreen)').matches) {
        return 'fullscreen';
      }
      if (window.matchMedia('(display-mode: minimal-ui)').matches) {
        return 'minimal-ui';
      }
      return 'browser';
    };

    const displayMode = getDisplayMode();
    return `VERSION-${displayMode}`;
  } catch (error) {
    console.error(error);
    return 'VERSION-unknown';
  }
};

const localStorageVersionKey = getLocalStorageVersionKey();

export const getLocalVersion = () => {
  return localStorage.getItem(localStorageVersionKey) ?? '0';
};

export const updateApp: UpdateAppFn = async () => {
  try {
    const timestamp = new Date().getTime();
    // include timestamp in version request to avoid caching
    const remoteVersionResponse = await fetch(`/VERSION?${timestamp}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    const remoteVersion = await remoteVersionResponse.text();
    const localVersion = getLocalVersion();
    console.log(`Checking for new version. Remote version: ${remoteVersion}, Local version: ${localVersion}`);
    if (remoteVersion != localVersion) {
      localStorage.setItem(localStorageVersionKey, remoteVersion);
      console.log('Version mismatch. Reloading app.');
      location.reload();
    }
  } catch (error) {
    console.error('Could not fetch the remote version', error);
  }
};
