import { FC, Fragment } from 'react';

interface TextWithBreaksProps {
  children: string;
}

/**
 * Returns the text supplied in children with inserted word break opportunities (<wbr />)
 * after hyphens (-), forward slashes (/), and backslashes (\).
 */
export const TextWithBreaks: FC<TextWithBreaksProps> = ({ children = '' }) => {
  const parts = children.split(/([-\\/])/g);

  const withBreaks = parts.map((part, index) => (
    <Fragment key={`${index}-${part}`}>
      {part}
      {['-', '/', '\\'].includes(part) && <wbr />}
    </Fragment>
  ));

  return <>{withBreaks}</>;
};
