import { LABELS } from '../../assets/LABELS';
import { PathSegmentResolver, ResolvedPathSegment } from '../../utils/use-path';

export const resolveInfoPath: PathSegmentResolver = ({ defaultLink, nextPathSegment: selectedLabelKey }) => {
  const resolvedInformationPathname: ResolvedPathSegment = {
    title: 'Information',
    isPageTitle: true,
    link: defaultLink,
  };

  if (selectedLabelKey) {
    // path is like "/info/{selectedLabelKey}"
    const selectedLabel = LABELS.find(({ key }) => key === selectedLabelKey);
    if (selectedLabel) {
      const resolvedPathSegments: ResolvedPathSegment[] = [
        resolvedInformationPathname,
        {
          title: selectedLabel?.title,
          link: `${defaultLink}/${selectedLabel?.link}`,
        },
      ];
      return resolvedPathSegments;
    } else {
      console.warn('Label not found while resolving a path segment', { LABELS, selectedLabelKey });
    }
  }

  // path is like "/info"
  return resolvedInformationPathname;
};
