export enum DocumentType {
  TECHNICAL_INFORMATION = 'TECHNICAL_INFORMATION',
  SAFETY_INFORMATION = 'SAFETY_INFORMATION',
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
}

export const LABELED_DOCUMENT_TYPE = [
  {
    key: DocumentType.TECHNICAL_INFORMATION,
    label: 'Technical Information',
  },
  {
    key: DocumentType.SAFETY_INFORMATION,
    label: 'Safety Information',
  },
  {
    key: DocumentType.GENERAL_INFORMATION,
    label: 'General Information',
  },
];

export const toLabeledDocumentType = (documentType?: DocumentType): string | undefined => {
  return LABELED_DOCUMENT_TYPE.find(({ key }) => key === documentType)?.label;
};
