import { CONTACT } from '../../assets/CONTACT';
import { List } from '../../components/ui/list/list';
import { useAppContainer } from '../components/use-app-container';
import './contact-page.scss';

export const ContactPage = () => {
  useAppContainer({ scrollMainToTopOnDeps: [] });

  return (
    <div className="contact-page">
      <h1 className="text-center pt-16 lg:hidden">Kontakt</h1>
      <div className="content mx-auto mt-6 mb-5">
        <List
          items={CONTACT}
          className="max-lg:mx-5"
          listClassName="!grid-cols-1 lg:!grid-cols-2"
          fillBgOnHover
          extraListItemPadding
        />
      </div>
    </div>
  );
};
