import { useState } from 'react';

interface ImageProps {
  hideOnError?: boolean;
  imageProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
}

export const Image = (props: ImageProps) => {
  const { hideOnError, imageProps } = props;
  const [imageError, setImageError] = useState<boolean>(false);

  const handleImageError = () => setImageError(true);

  const hideImage = hideOnError && imageError;

  return hideImage ? (
    <></>
  ) : (
    <img
      {...imageProps}
      onError={(e) => {
        handleImageError();
        if (imageProps.onError) imageProps.onError(e);
      }}
    />
  );
};
