const SERVICE_WORKER_VERSION = 11;

export const registerServiceWorker = async (scriptURL: string) => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(`${scriptURL}?v=${SERVICE_WORKER_VERSION}`);
      if (registration.installing) {
        console.log(`Service worker installing (${scriptURL})`);

        await new Promise((resolve) => {
          if (!registration.installing) return resolve(true);

          // add an event listener to track state changes and wait for installation
          registration.installing.addEventListener('statechange', (event) => {
            if (event.target && 'state' in event.target && event.target.state === 'installed') {
              console.log(`Service worker installed (${scriptURL})`);

              // reload page to fetch everything again with active service worker
              location.reload();
              resolve(true);
            }
          });
        });
      } else if (registration.waiting) {
        console.log(`Service worker installed (${scriptURL})`);
      } else if (registration.active) {
        console.log(`Service worker active (${scriptURL})`);
      }
    } catch (error: unknown) {
      console.error(`Registration of (${scriptURL}) failed (${typeof error === 'string' ? error : 'Unknown error'})`);
    }
  } else {
    console.error("Browser doesn't support serviceWorker. ('serviceWorker' not found in navigator)");
  }
};
