import { useNavigate } from 'react-router-dom';
import { useGetProducts } from '../../api/queries/use-get-products';
import { BackButton } from '../../components/back-button';
import { PathBreadcrumbs } from '../../components/hierarchy-breadcrumbs/path-breadcrumbs';
import { Scanner } from '../../components/scanner/scanner';
import { useAppContainer } from '../components/use-app-container';
import './barcode-search-page.scss';

export const BarcodeSearchPage = () => {
  useAppContainer({ scrollMainToTopOnDeps: [] });
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetProducts();

  const onDetected = (text: string) => {
    // get product id with scanned EAN code
    const product = data?.['hydra:member'].find(({ articles }) =>
      articles.find(({ europeanArticleNumber }) => europeanArticleNumber === text),
    );

    if (product) {
      navigate(`product/${product.id}`);
    } else {
      // about half of the detections are not correctly decoded, so don't spam the user
      console.info(`Product of scanned article number not found (EAN: ${text}).`);
    }
  };

  return (
    <div className="barcode-search-page h-full">
      <div className="content mx-auto pt-4 mb-5 max-lg:px-5">
        <PathBreadcrumbs className="mt-4 mb-7" />
        <BackButton />
        <h1 className="text-center pt-5 lg:hidden">Scanna streckod</h1>
        <div className="pt-9 flex items-center justify-center flex-col">
          {isLoading ? 'Loading products...' : isError ? 'Failed to load products' : <Scanner onResult={onDetected} />}
        </div>
      </div>
    </div>
  );
};
