import { ContactIcon } from '../ui/icons/contact-icon';
import { InfoIcon } from '../ui/icons/info-icon';
import { LinksIcon } from '../ui/icons/links-icon';
import { SearchIcon } from '../ui/icons/search-icon';
import { Icon } from '../ui/icons/type';

export interface Path {
  to: string;
  text: string;
  title: string;
  icon: Icon;
}

export const PATHS: Path[] = [
  {
    to: '/search',
    text: 'Sök',
    title: 'Sök produkt',
    icon: SearchIcon,
  },
  {
    to: '/info',
    text: 'Info',
    title: 'Information',
    icon: InfoIcon,
  },
  {
    to: '/contact',
    text: 'Kontakt',
    title: 'Kontakt',
    icon: ContactIcon,
  },
  {
    to: '/links',
    text: 'Länkar',
    title: 'Länkar',
    icon: LinksIcon,
  },
];
