import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import './breadcrumbs.scss';

export interface BreadcrumbItem {
  key: string | number;
  title: string;
  link?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  className?: string;
}

export const Breadcrumbs = ({ items, className }: BreadcrumbsProps) => {
  const navigate = useNavigate();

  return (
    <div className={`breadcrumbs flex gap-x-2 ${className}`}>
      {items.map(({ key, title, link }, i) => {
        const isLastItem = i === items.length - 1;

        const onClick = () => {
          if (!isLastItem && link) {
            navigate(`${link}`);
          }
        };

        return (
          <Fragment key={key}>
            <span
              onClick={onClick}
              className={`${isLastItem ? 'text-medium' : ''}
                                        ${!isLastItem && link ? 'cursor-pointer' : 'select-none'}`}
            >
              {title}
            </span>
            {!isLastItem && <span className="arrow">{'>'}</span>}
          </Fragment>
        );
      })}
    </div>
  );
};
