import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

export interface AppContainerOutletContext {
  scrollMainToTop: () => void;
}

type UseAppContainer = (props?: { scrollMainToTopOnDeps?: unknown[] }) => AppContainerOutletContext;

export const useAppContainer: UseAppContainer = ({ scrollMainToTopOnDeps } = {}) => {
  const { scrollMainToTop } = useOutletContext<AppContainerOutletContext>();

  useEffect(() => {
    if (scrollMainToTopOnDeps) {
      scrollMainToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, scrollMainToTopOnDeps ?? []);

  return { scrollMainToTop };
};
