import { Icon } from '../icons/type';
import './button.scss';

interface ButtonProps {
  icon?: Icon;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  rounded?: boolean;
  className?: string;
}

export const Button = ({ icon: IconElement, onClick, rounded = false, label = '', className = '' }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`
                cursor-pointer button p-0 flex justify-center items-center border-none
                ${
                  // make icon-only buttons all the same size
                  IconElement && !label ? 'w-8 h-8' : ''
                }
                ${rounded ? 'rounded-full' : ''} 
                ${label ? 'px-3.5 py-2' : ''}
                ${!IconElement ? 'shadow' : ''}
                ${className}
            `}
    >
      {IconElement && <IconElement />}
      {label}
    </button>
  );
};
