import { JSX, MouseEventHandler, useState } from 'react';
import './tabs.scss';

interface TabsProps {
  tabs: {
    key: string;
    title: string;
    content: JSX.Element;
  }[];
}

export const Tabs = ({ tabs }: TabsProps) => {
  const [activeTabKey, setActiveTabKey] = useState(tabs.length > 0 ? tabs[0]?.key : undefined);

  const getOnClickHandler =
    (key: string): MouseEventHandler<HTMLSpanElement> =>
    (e) => {
      setActiveTabKey(key);
      e.currentTarget.scrollIntoView({ block: 'nearest' });
    };

  const activeContent = tabs.find(({ key }) => key === activeTabKey)?.content;

  return (
    <div className="tabs">
      <div className="titles">
        <div className="center-container flex px-9 lg:px-6">
          {tabs.map(({ key, title }) => {
            const isActiveTab = key === activeTabKey;

            return (
              <div key={key} className={`flex flex-col relative ${isActiveTab && 'active-tab'}`}>
                <span
                  onClick={getOnClickHandler(key)}
                  className={`w-max select-none cursor-pointer py-2 pl-2 pr-2 lg:pl-5 lg:pr-5 text-small-desktop
                              ${isActiveTab ? 'text-medium' : ''}`}
                >
                  {title}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="pt-7">{activeContent}</div>
    </div>
  );
};
