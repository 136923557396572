import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateUp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return () => {
    const state: unknown = window.history?.state;
    const idx = typeof state === 'object' && state && 'idx' in state ? state.idx : undefined;

    // Check if the browser has navigation history
    if (idx !== 0) {
      navigate(-1); // Use browser navigation if available
    } else {
      // Split the current pathname into an array of segments
      const segments = location.pathname.split('/');

      // Check if there are at least two segments
      if (segments.length >= 3) {
        // Remove the last segment (current page) and join the remaining segments
        const parentPath = segments.slice(0, -1).join('/');

        // Navigate to the parent path
        navigate(parentPath + location.search);
      } else {
        // If there are fewer than two segments, navigate to the root path
        navigate('/');
      }
    }
  };
};
