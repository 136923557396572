import './download-list.scss';

interface DownloadListProps {
  items: {
    key: string;
    title: string;
    subtitle?: string;
    src: string;
  }[];
}

export const DownloadList = ({ items }: DownloadListProps) => {
  return (
    <table className="download-list w-full table-auto border-spacing-x-0 border-spacing-y-1 text-small-desktop">
      <tbody>
        {items.map(({ key, src, subtitle, title }) => {
          const onClick = () => {
            window.open(src, '_blank');
          };

          return (
            <tr key={key} onClick={onClick} className="cursor-pointer">
              <td className="p-4 w-0 hidden sm:table-cell">
                <span className="material-icons align-middle">description</span>
              </td>
              <td className="p-4 break-all">
                <strong>{title}</strong>
              </td>
              <td className="p-4">{subtitle}</td>
              <td className="p-4 w-0 metadata hidden sm:table-cell">PDF</td>
              <td className="p-4 w-0">
                <span className="material-icons align-middle">download</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
