export enum M1Status {
  NOT_RELEVANT = 'not_relevant',
  M1 = 'm1',
}

export const LABELED_M1STATUS = [
  {
    key: M1Status.NOT_RELEVANT,
    label: 'Inte relevant',
  },
  {
    key: M1Status.M1,
    label: 'M1',
  },
];

export const toLabeledM1Status = (m1Status?: M1Status): string | undefined => {
  return LABELED_M1STATUS.find(({ key }) => key === m1Status)?.label;
};
