/**
 * Extracts an ID from a URL-like string, e.g. 42 for "/api/campaigns/42".
 *
 * @param {string} text - The URL-like string containing the ID.
 * @returns {number} The extracted ID as a number.
 */
export const extractURLId = (text: string): number => {
  // Split the input text by '/'
  const parts = text.split('/');

  // Get the last part of the split string
  const lastPart = parts[parts.length - 1];

  // Parse the last part as an integer with base 10
  const id = parseInt(lastPart, 10);

  // Return the extracted ID
  return id;
};
