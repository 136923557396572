import { useQuery } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { extractURLId } from '../../utils/extract-url-id';
import { httpFetch } from '../../utils/http-fetch';
import { BastaBetaStatus, BvbStatus, Emicode, GreenBuildingRating, HealthyHousesRating, M1Status } from '../enums';
import { DocumentType } from '../enums/DocumentType';
import { ImageType } from '../enums/ImageType';
import { HydraMultiResponse } from '../type';

export const PRODUCTS_QUERY_KEY = 'products';

export const getProducts = async () => {
  const products = await httpFetch<HydraMultiResponse<ResponseProduct>>(`/api/products.jsonld`);
  const processedProducts = {
    ...products,
    'hydra:member': products['hydra:member'].map((product) => {
      return {
        ...product,
        categories: product.categories?.map(extractURLId),
        description: DOMPurify.sanitize(product.description),
      };
    }),
  };

  return processedProducts;
};

export const useGetProducts = () => {
  return useQuery<HydraMultiResponse<Product>>({ queryKey: [PRODUCTS_QUERY_KEY], queryFn: getProducts });
};

export interface Product {
  id: number;
  categories: number[];
  pimId: string;
  name: string;
  description: string;
  shortDescription: string;
  m1Status: M1Status;
  emicode: Emicode;
  bastaBetaStatus: BastaBetaStatus;
  svanensHousePortal: boolean;
  healthyHousesRating: HealthyHousesRating;
  bvbId: string;
  bvbContents: BvbStatus;
  bvbLifecycle: BvbStatus;
  bvbTotal: BvbStatus;
  greenBuildingIndicator13: GreenBuildingRating;
  greenBuildingIndicator14: GreenBuildingRating;
  A1A3Co2: string;
  additionalContent: string;
  category: string;
  isEnabled: boolean;
  documents: Document[];
  images: Image[];
  articles: Article[];
}
type ResponseProduct = Product & {
  categories: string[];
};

export interface Article {
  id: number;
  articleNumber: string;
  color: string;
  packaging: string;
  europeanArticleNumber: string;
}

interface Document {
  id: number;
  active: boolean;
  title: string;
  type: DocumentType;
  url: string;
  product: ProductUrl;
}

interface Image {
  id: number;
  code: string;
  imageUrl: string;
  product: ProductUrl;
  altText: string;
  type: ImageType;
}

// typing of string for product urls like "/api/products/123"
type ProductUrl = string;
