import {
  toLabeledBastaStatus,
  toLabeledBvbStatus,
  toLabeledEmicode,
  toLabeledGreenBuildingRating,
  toLabeledHealthyHousesRating,
  toLabeledM1Status,
} from '../../../api/enums';
import { StripedRows } from '../../../components/ui/striped-rows/striped-rows';
import { DATA_UNAVAILABLE_TEXT, TabContentProps } from './tab';

export const EnvironmentTabContent = ({ product }: TabContentProps) => {
  const {
    bvbContents, // Databas_explained.xlsx: "BVB Innehäll"
    bvbLifecycle, // Databas_explained.xlsx: "BVB Livscykel"
    bvbTotal, // Databas_explained.xlsx: "BVB Total"
    healthyHousesRating, // Databas_explained.xlsx: "Sunda Hus"
    svanensHousePortal, // Databas_explained.xlsx: "Svanen husportal"
    bastaBetaStatus, // Databas_explained.xlsx: "Basta-Beta status"
    greenBuildingIndicator13, // Databas_explained.xlsx: "Miljöbyggnad 3.0 - Indikator 13"
    greenBuildingIndicator14, // Databas_explained.xlsx: "Miljöbyggnad 3.0 - Indikator 14"
    m1Status, // Databas_explained.xlsx: "M1"
    emicode, // Databas_explained.xlsx: "EMICODE"
    bvbId, // Database_explained.xlsx: "BVB ID"
    A1A3Co2,
    additionalContent,
  } = product ?? {};

  const rows = [
    {
      key: 'bvb-innehall',
      title: 'BVB Innehåll',
      value: bvbContents,
      text: toLabeledBvbStatus(bvbContents),
    },
    {
      key: 'bvb-livscykel',
      title: 'BVB Livscykel',
      value: bvbLifecycle,
      text: toLabeledBvbStatus(bvbLifecycle),
    },
    {
      key: 'bvb-total',
      title: 'BVB Total',
      value: bvbTotal,
      text: toLabeledBvbStatus(bvbTotal),
    },
    {
      key: 'sunda-hus',
      title: 'Sunda Hus',
      value: healthyHousesRating,
      text: toLabeledHealthyHousesRating(healthyHousesRating),
    },
    {
      key: 'svanen-husportal',
      title: 'Svanen husportal',
      value: svanensHousePortal,
      text: svanensHousePortal ? 'Ja' : 'Nej',
    },
    {
      key: 'basta',
      title: 'BASTA',
      value: bastaBetaStatus,
      text: toLabeledBastaStatus(bastaBetaStatus),
    },
    {
      key: 'miljöbyggnad-3.0-indikator-13',
      title: 'Miljöbyggnad 3.0 - Indikator 13 ',
      value: greenBuildingIndicator13,
      text: toLabeledGreenBuildingRating(greenBuildingIndicator13),
    },
    {
      key: 'miljöbyggnad-3.0-indikator-14',
      title: 'Miljöbyggnad 3.0 - Indikator 14',
      value: greenBuildingIndicator14,
      text: toLabeledGreenBuildingRating(greenBuildingIndicator14),
    },
    {
      key: 'm1',
      title: 'M1',
      value: m1Status,
      text: toLabeledM1Status(m1Status),
    },
    {
      key: 'emicode',
      title: 'EMICODE',
      value: emicode,
      text: toLabeledEmicode(emicode),
    },
    {
      key: 'bvb-id',
      title: 'BVB ID',
      value: bvbId,
      text: bvbId,
    },
    {
      key: 'A1A3Co2',
      title: 'A1-3 kg CO₂-Eq',
      value: A1A3Co2,
      text: A1A3Co2,
    },
  ]
    .filter(({ value }) => value !== undefined)
    .sort(({ title: titleA }, { title: titleB }) => titleA.localeCompare(titleB));

  return rows.length > 0 || additionalContent ? (
    <div className="pb-6 flex flex-col gap-4">
      {rows.length > 0 && <StripedRows rows={rows} />}
      {additionalContent && <div className="text-small-desktop whitespace-pre-wrap px-5">{additionalContent}</div>}
    </div>
  ) : (
    DATA_UNAVAILABLE_TEXT
  );
};
