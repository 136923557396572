import { LINKS } from '../../assets/LINKS';
import { List } from '../../components/ui/list/list';
import { useAppContainer } from '../components/use-app-container';
import './links-page.scss';

export const LinksPage = () => {
  useAppContainer({ scrollMainToTopOnDeps: [] });

  return (
    <div className="links-page">
      <h1 className="text-center pt-16 lg:hidden">Länkar</h1>
      <div className="content mx-auto mt-6 mb-5">
        <List
          items={LINKS}
          className="max-lg:mx-5"
          listClassName="!grid-cols-1 lg:!grid-cols-2"
          fillBgOnHover
          extraListItemPadding
        />
      </div>
    </div>
  );
};
