import React from 'react';
import { Icon } from '../icons/type';
import { Image } from '../image/image';
import { TextWithBreaks } from '../text-with-breaks/text-with-breaks';
import './list-item.scss';

export interface Item {
  key: string | number;
  title: string;
  description?: string;
  imageSrc?: string;
  imageAlt?: string;
  link?: string;
  mail?: string;
  onClick?: (item: Item) => void;
  hideOnDesktop?: boolean;
  Icon?: Icon;
  imageUseFullWidth?: boolean;
  fullWidth?: boolean;
}

export interface ListItemProps {
  item: Item;
  hideDescription?: boolean;
  hideImage?: boolean;
  customListItemRenderer?: (item: Item) => React.JSX.Element;
  customImageRenderer?: (item: Item) => React.JSX.Element;
  onClick: () => void;
  lightHeader?: boolean;
  fillBgOnHover: boolean;
  extraListItemPadding?: boolean;
  centerText: boolean;
}

export const ListItem = ({
  item,
  hideDescription,
  hideImage,
  onClick,
  lightHeader,
  customListItemRenderer,
  fillBgOnHover,
  extraListItemPadding,
  customImageRenderer,
  centerText,
}: ListItemProps) => {
  const { title, description, imageSrc, hideOnDesktop, Icon, fullWidth, imageAlt } = item;
  const showDescription = !hideDescription && description;

  return (
    <li
      className={`min-h-24 cursor-pointer listitem sika-transition
                  ${hideOnDesktop ? 'lg:hidden' : ''}
                  ${fullWidth ? 'lg:col-span-3' : ''}
                  ${fillBgOnHover ? 'fill-bg-on-hover' : ''}
                  ${extraListItemPadding ? 'p-4' : ''}`}
      onClick={onClick}
    >
      {customListItemRenderer ? (
        customListItemRenderer(item)
      ) : (
        <div className="flex flex-col gap-2 h-full">
          {!hideImage && imageSrc && (
            <div className="image-container flex items-center justify-center py-2 px-4">
              {customImageRenderer ? (
                customImageRenderer(item)
              ) : (
                <Image
                  hideOnError={true}
                  imageProps={{
                    src: imageSrc,
                    className: 'object-contain w-32 lg:w-full h-full',
                    width: '100%',
                    height: 128,
                    loading: 'lazy',
                    alt: imageAlt,
                  }}
                />
              )}
            </div>
          )}
          <div className="flex gap-1 flex-col p-4 h-full">
            {Icon && <Icon className="mb-2" />}
            <div
              className={`${!showDescription ? 'flex grow items-center h-full' : ''}
                          ${centerText ? 'justify-center text-center' : ''}`}
            >
              <span className={`text-black overflow-auto text-2xl ${lightHeader ? '' : 'font-bold'}`}>
                <TextWithBreaks>{title}</TextWithBreaks>
              </span>
            </div>
            {showDescription && <div className="text-small text-smaller-desktop line-clamp-4">{description}</div>}
          </div>
        </div>
      )}
    </li>
  );
};
