export enum ImageType {
  PRODUCT_PICTURE = 'PRODUCT_PICTURE',
  LOGO1 = 'LOGO1',
  LOGO2 = 'LOGO2',
  LOGO3 = 'LOGO3',
  PROMO1 = 'PROMO1',
}

// product picture is considered the "primary" image of the product
export const PRIMARY_IMAGE_TYPE = ImageType.PRODUCT_PICTURE;
