import { useLocation, useNavigate } from 'react-router-dom';
import { Category, useGetCategories } from '../api/queries/use-get-categories';
import { Product, useGetProducts } from '../api/queries/use-get-products';
import { HydraMember } from '../api/type';
import { resolveCategoryPath } from '../pages/category-page/resolve-category-path';
import { resolveContactPath } from '../pages/contact-page/resolve-contact-path';
import { resolveInfoPath } from '../pages/info-page/resolve-info-path';
import { resolveLinksPath } from '../pages/links-page/resolve-links-path';
import { resolveProductPath } from '../pages/product-page/resolve-product-path';
import { resolveBarcodeSearchPath } from '../pages/search-page/resolve-barcode-search-path';
import { resolveFilterSearchPath } from '../pages/search-page/resolve-filter-search-path';
import { resolveNameSearchPath } from '../pages/search-page/resolve-name-search-path';
import { resolveSearchPath } from '../pages/search-page/resolve-search-page';

export type PathSegmentResolver = (params: {
  defaultLink: string;
  nextPathSegment?: string;
  categories: HydraMember<Category>[];
  products: HydraMember<Product>[];
}) => ResolvedPathSegment | ResolvedPathSegment[];

export interface ResolvedPathSegment {
  title: string;
  link?: string;
  isPageTitle?: boolean;
  hidePageTitle?: boolean;
}

type UseResolvedPath = () => {
  path: ResolvedPathSegment[];
  navigationTitle?: string;
  navigateUp: () => void;
};

export const useResolvedPath: UseResolvedPath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const { data: productsData } = useGetProducts();
  const products = productsData?.['hydra:member'] ?? [];
  const { data: categoriesData } = useGetCategories();
  const categories = categoriesData?.['hydra:member'] ?? [];

  const resolvedPathSegments: ((ResolvedPathSegment | undefined)[] | ResolvedPathSegment | undefined)[] =
    pathSegments.map((pathPart, i) => {
      const defaultLink = pathSegments.slice(0, i + 1).join('/');
      const nextPathSegment = pathSegments.length - 1 !== i ? decodeURI(pathSegments[i + 1]) : undefined;
      const pathSegmentResolverParams = { categories, products, defaultLink, nextPathSegment };

      switch (pathPart) {
        case 'search':
          return resolveSearchPath(pathSegmentResolverParams);
        case 'name':
          return resolveNameSearchPath(pathSegmentResolverParams);
        case 'barcode':
          return resolveBarcodeSearchPath(pathSegmentResolverParams);
        case 'filter':
          return resolveFilterSearchPath(pathSegmentResolverParams);
        case 'info':
          return resolveInfoPath(pathSegmentResolverParams);
        case 'contact':
          return resolveContactPath(pathSegmentResolverParams);
        case 'links':
          return resolveLinksPath(pathSegmentResolverParams);
        case 'product':
          return resolveProductPath(pathSegmentResolverParams);
        case 'category':
          return resolveCategoryPath(pathSegmentResolverParams);
        default:
          return undefined;
      }
    });

  const filteredAndFlattenedResolvedPathSegments: ResolvedPathSegment[] = resolvedPathSegments
    // filter out undefined values
    .filter((x) => x)
    .map((x) => x!)
    // flatten array of arrays to array
    .flatMap((resolvedPathSegment) =>
      Array.isArray(resolvedPathSegment) ? resolvedPathSegment : [resolvedPathSegment],
    )
    // filter out undefined values
    .filter((x) => x)
    .map((x) => x!);

  // navigation title is the last path segment where isPageTitle is true
  let navigationTitle: string | undefined = undefined;
  const pageTitlePathSegments = filteredAndFlattenedResolvedPathSegments.filter(({ isPageTitle }) => isPageTitle);
  if (pageTitlePathSegments.length > 0) {
    const lastSegment = pageTitlePathSegments[pageTitlePathSegments.length - 1];
    if (!lastSegment.hidePageTitle) {
      navigationTitle = lastSegment.title;
    }
  }

  const navigateUp = () => {
    // navigate to the second last path segment
    const segmentsLength = filteredAndFlattenedResolvedPathSegments.length;
    if (segmentsLength > 1) {
      const upLink = filteredAndFlattenedResolvedPathSegments[segmentsLength - 2].link;
      navigate(`${upLink}`);
    } else {
      console.warn('Tried to navigate up without having a deep enough hierarchy', {
        filteredAndFlattenedResolvedPathSegments,
      });
    }
  };

  return { path: filteredAndFlattenedResolvedPathSegments, navigationTitle, navigateUp };
};
