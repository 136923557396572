import { useResolvedPath } from '../utils/use-path';
import { Button } from './ui/button/button';
import { ChevronLeftIcon } from './ui/icons/chevron-left';

interface BackButtonProps {
  className?: string;
}

export const BackButton = ({ className }: BackButtonProps) => {
  const { navigateUp } = useResolvedPath();

  return (
    <div onClick={navigateUp} className={`cursor-pointer inline-flex gap-3.5 items-center ${className}`}>
      <Button rounded icon={ChevronLeftIcon} />
      <span className="max-lg:hidden text-smaller-desktop mb-0.5">tillbaka</span>
    </div>
  );
};
