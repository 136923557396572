import { SVGProps } from 'react';
import { Icon } from './type';

export const InfoIcon: Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="info" clipPath="url(#clip0_8_1972)">
      <path
        id="Vector"
        d="M11.8765 0.500001C19.3977 0.497203 24.1583 5.79028 24.6072 11.3862C24.8897 14.9007 23.8695 17.9949 21.4892 20.5952C19.3488 22.934 16.6594 24.2419 13.4988 24.4657C9.90039 24.721 6.79288 23.5313 4.21891 21.0114C2.10574 18.9425 0.93728 16.396 0.697434 13.4564C0.415632 9.99439 1.4855 6.94639 3.80914 4.38588C6.14397 1.81278 9.11443 0.57134 11.8765 0.500001ZM13.4932 22.8137C15.6875 22.7928 18.2586 21.6604 20.2998 19.423C22.3521 17.1724 23.2073 14.479 22.9318 11.4457C22.5081 6.77364 18.4838 1.97504 12.18 2.18906C9.28015 2.28767 6.82855 3.47526 4.90628 5.68187C2.97982 7.89339 2.11763 10.4763 2.37426 13.39C2.6204 16.1841 3.82732 18.5271 5.96776 20.3532C7.88443 21.9877 10.1109 22.8221 13.4932 22.8137Z"
      />
      <path
        id="Vector_2"
        d="M11.8381 14.1502C11.8381 13.2172 11.8458 12.2835 11.8339 11.3505C11.8297 11.0155 12.0738 10.5917 12.5339 10.5511C13.0171 10.5085 13.3758 10.7553 13.459 11.1967C13.4891 11.3547 13.5093 11.5177 13.51 11.6786C13.5135 13.3879 13.5149 15.0979 13.51 16.8073C13.5086 17.2038 13.3884 17.557 12.987 17.7207C12.4437 17.9424 11.8451 17.6109 11.8402 17.0024C11.8332 16.0519 11.8381 15.1007 11.8381 14.1502Z"
      />
      <path
        id="Vector_3"
        d="M12.615 9.13622C11.9689 9.14672 11.5381 8.59419 11.5353 8.01928C11.5325 7.51711 11.9129 6.85337 12.7982 6.93101C13.3024 6.97507 13.8016 7.48074 13.7765 8.06614C13.752 8.62776 13.2723 9.1656 12.6143 9.13622H12.615Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_1972">
        <rect width="24" height="24" transform="translate(0.653442 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
