export enum Emicode {
  NOT_RELEVANT = 'not_relevant',
  EC1_PLUS = 'ec1_plus',
}

export const LABELED_EMICODE = [
  {
    key: Emicode.NOT_RELEVANT,
    label: 'Inte relevant',
  },
  {
    key: Emicode.EC1_PLUS,
    label: 'EC1+',
  },
];

export const toLabeledEmicode = (emicode?: Emicode): string | undefined => {
  return LABELED_EMICODE.find(({ key }) => key === emicode)?.label;
};
