import { SVGProps } from 'react';
import { Icon } from './type';

export const ContactIcon: Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="19" viewBox="0 0 25 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Isolationsmodus" clipPath="url(#clip0_8_1890)">
      <path
        id="Vector"
        d="M0.326721 16.7227C0.326721 13.0172 0.326721 9.31106 0.326721 5.60552C0.420655 5.6519 0.521477 5.68825 0.607895 5.7459C3.77533 7.85842 6.94339 9.97157 10.1077 12.0891C10.7001 12.4858 11.3351 12.7759 12.0471 12.8223C12.8756 12.8768 13.6471 12.6525 14.3397 12.1919C17.0726 10.3708 19.8016 8.54463 22.5326 6.72101C23.1187 6.32996 23.7061 5.9408 24.3273 5.52782V5.7459C24.3273 9.22458 24.328 12.7033 24.3248 16.1819C24.3248 16.3655 24.3098 16.5535 24.2716 16.7328C24.0556 17.7449 23.1131 18.5 22.0617 18.4994C15.6091 18.4931 9.15647 18.4962 2.70386 18.4962C2.61807 18.4962 2.53228 18.495 2.44586 18.4931C1.76453 18.4781 1.20469 18.1942 0.804529 17.6552C0.599128 17.3782 0.483277 17.0348 0.326721 16.7221L0.326721 16.7227Z"
      />
      <path
        id="Vector_2"
        d="M0.326721 2.27566C0.483277 1.96107 0.603512 1.62078 0.805156 1.33815C1.15271 0.851223 1.65306 0.571099 2.25611 0.544779C3.01134 0.511565 3.76719 0.503418 4.52304 0.502791C10.3356 0.500285 16.1482 0.498405 21.9608 0.504045C23.0517 0.505298 23.8214 1.01228 24.172 1.94352C24.5165 2.85784 24.2522 3.88872 23.2878 4.52981C20.0747 6.66489 16.8715 8.81439 13.6634 10.957C13.2326 11.2446 12.7654 11.432 12.2356 11.4226C11.7396 11.4132 11.3207 11.1995 10.9193 10.9313C7.70551 8.78055 4.49048 6.63105 1.27232 4.48594C0.788248 4.1632 0.49267 3.72766 0.371183 3.16428C0.367426 3.14674 0.341751 3.1342 0.326721 3.11979C0.326721 2.83841 0.326721 2.55703 0.326721 2.27566Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_1890">
        <rect width="24" height="18" transform="translate(0.326721 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
