import { ArrowUpIcon } from '../ui/icons/arrow-up-icon';
import { InlineLink } from '../ui/inline-link/inline-link';
import './desktop-footer.scss';

interface DesktopFooterProps {
  className?: string;
  scrollMainToTop: () => void;
}

const FOOTER_LINKS = [
  {
    text: 'Allmänna försäljnings- och leveransvillkor',
    to: 'https://swe.sika.com/dam/dms/se01/b/Leveransvillkor.pdf',
  },
  {
    text: 'Legal notice',
    to: 'https://swe.sika.com/sv/legl-notice.html',
  },
  {
    text: 'Behandling av personuppgifter',
    to: 'https://swe.sika.com/sv/legl-notice/privacy-policy.html',
  },
  {
    text: 'Utnyttja dina rättigheter ',
    to: 'https://app-de.onetrust.com/app/#/webform/f4ee793a-4a47-41fc-9810-2caf33566546',
  },
];

export const DesktopFooter = ({ className, scrollMainToTop }: DesktopFooterProps) => {
  return (
    <footer className={`desktop-footer mt-20 ${className}`}>
      <div className="pl-36 pr-28">
        <div className="relative content mx-auto">
          <div
            onClick={scrollMainToTop}
            className="back-to-top-container h-11 w-11 absolute right-0 cursor-pointer mr-9 -mt-3 flex items-end justify-center"
          >
            <ArrowUpIcon className="mb-1" />
          </div>
          <div className="flex justify-between">
            <div className="pt-10">
              <h5>Sika Sverige AB</h5>
              <p>
                Domnarvsgatan 15
                <br />
                SE-163 53 Spånga
                <br />
                Box 8061
              </p>
              <div className="flex flex-col gap-1.5">
                <div>
                  Tel.:{' '}
                  <InlineLink className="border-0" target="_blank" rel="noreferrer" href="tel:08-621%2089%2000">
                    08-621 89 00
                  </InlineLink>
                </div>
                <div>
                  Email:{' '}
                  <InlineLink target="_blank" rel="noreferrer" href="mailto:info@se.sika.com">
                    info@se.sika.com
                  </InlineLink>
                </div>
              </div>
            </div>
            <img src="/logo-with-slogan.svg" className="py-10" />
          </div>
        </div>
      </div>
      <div className="pl-36 pr-28 links">
        <ul className="content mx-auto m-0 pl-0 py-4 grid grid-flow-col justify-start">
          {FOOTER_LINKS.map(({ to, text }) => {
            return (
              <a
                key={to}
                href={to}
                target="_blank"
                rel="noreferrer"
                className="first:pl-0 last:pr-0 px-4 cursor-pointer no-underline text-inherit"
              >
                <li>{text}</li>
              </a>
            );
          })}
        </ul>
      </div>
    </footer>
  );
};
