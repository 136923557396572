import { NewTabIcon } from '../components/ui/icons/new-tab-icon';
import { Item } from '../components/ui/list/list-item';

export const LINKS: Item[] = [
  {
    key: 'casco.se',
    title: 'Casco.se',
    link: 'https://www.casco.se',
    Icon: NewTabIcon,
  },
  {
    key: 'schonox.se',
    title: 'Schonox.se',
    link: 'https://www.schonox.se',
    Icon: NewTabIcon,
  },
  {
    key: 'pci-sverige.se',
    title: 'PCI-sverige.se',
    link: 'https://www.pci-sverige.se/sv',
    Icon: NewTabIcon,
  },
  {
    key: 'sika.se',
    title: 'Sika.se',
    link: 'https://sika.se',
    Icon: NewTabIcon,
  },
  {
    key: 'sika-kunskapsportal',
    title: 'Sika Kunskapsportal',
    description: `Här hittar du kunskapsrelaterad information; online kurser, instruktionsfilmer och webbinar.`,
    link: 'https://swe.sika.com/sv/kunskapsportalen.html',
    Icon: NewTabIcon,
  },
  {
    key: 'sika-e-shop',
    title: 'Sika E-shop',
    description: `Är du kund och vill handla i vår e-shop gör du det här.`,
    link: 'https://se.shop.sika.com',
    Icon: NewTabIcon,
  },
];
